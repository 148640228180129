var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "item",
      attrs: {
        "visible-arrow": false,
        "popper-class": "atooltip",
        effect: "dark",
        placement: "top-start",
      },
    },
    [
      _c("div", {
        attrs: { slot: "content" },
        domProps: { innerHTML: _vm._s(_vm.tip) },
        slot: "content",
      }),
      _c("img", {
        staticClass: "title_img",
        attrs: { src: require("./img/icon.png"), alt: "" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }