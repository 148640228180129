<!--
 * @Author: DZM
 * @Date: 2022-08-16 13:35:56
 * @LastEditors: lijing
 * @LastEditTime: 2022-09-01 11:33:39
 * @Description: 
-->
<template>
  <div class="bgRightDP">
    <div class="rightChartsContent">
      <div class="chartsContent">
        <div class="flexItem">
          <filst></filst>
        </div>
        <div class="flexItem">
          <numberServiceVehicles></numberServiceVehicles>
        </div>
        <div class="flexItem">
          <serviceIncome></serviceIncome>
        </div>
        <div class="flexItem">
          <status></status>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import filst from "./first.vue";
import numberServiceVehicles from "./child/numberServiceVehicles.vue";
import serviceIncome from "./child/serviceIncome.vue";
import status from "./child/status.vue";

export default {
  name: "newRightCharts",
  data() {
    return {};
  },
  components: { filst, numberServiceVehicles, serviceIncome, status },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="stylus" scoped>
.chartsContent {
  display: flex;
  flex-direction: column;
  height: 100%;

  .flexItem {
    box-sizing: border-box;
    flex: 1;
  }
}

.bgRightDP {
  position: absolute;
  transform: translateY(-50%)
  // top: 46px;
  top:50%
  right: 16px;
  background: linear-gradient(90deg, rgba(0,5,32,0) 0%, rgba(0,5,32,0.7) 34%, rgba(0,5,32,0.8) 100%);
  z-index: 10;
  // height: 93%;
  height:878px
  width: 480px;
}
.rightChartsContent {
  position: absolute;
  right: 0;
  // top: 46px;
  z-index: 999;
  height: 100%;
  width: 440px;
  // min-width: 200px;
  background: linear-gradient(180deg, rgba(182, 214, 255, 0.1) 0%, rgba(132, 173, 255, 0.19) 100%);
  backdrop-filter: blur(5px);
}
</style>
