<!--
 * @Author: DZM
 * @Date: 2022-03-23 18:16:39
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-25 14:15:37
 * @Description: 
-->
<template>
<div class="content">
   <timeType :title="'剩余泊位预测'"></timeType>
    <div class="leftberthForecast" id="berthForecast"></div>
</div>
</template>

<script>
import timeType from '../../componets/title.vue'
export default {
  name: "berthForecast",
  data() {
    return {
      xList: [],
      dataList: [],
      echartsDOM: null
    };
  },
  computed: {
    getnewRegionalCollection() {
      return this.$store.state.newregionalCollection;
    }
  },
  watch: {
    getnewRegionalCollection: {
      handler(newVal) {
        this.getData(newVal);
      }
    }
  },
  components: {timeType},
  methods: {
    // 获取数据
    getData(data) {
      // this.dataList = [0, 0, 0, 0, 0, 0];
      // this.xList = [0, 0, 0, 0, 0, 0];
      this.dataList = [];
      this.xList = [];
      this.$api.leftSideApi.berthPredict({
        parkId: data.parkId
      }).then(res => {
        if (res.state == 0 && res.value.predictList) {
          res.value.predictList.forEach((val) => {
            this.dataList.push({
              value: val.percent,
              type: val.stateName
            });
            this.xList.push(val.currentTime)
          })
          // this.initCharts();
        }
       this.initCharts();
      })
    },
    // 初始化图表
    initCharts() {
      console.log("top");
      var that = this;
      var chartDom = document.getElementById("berthForecast");
      var myChart = this.$echarts.init(chartDom);
      this.echartsDOM = myChart;
      var option = {
        color: ["rgba(165, 184, 255, 1)", "rgba(255, 255, 255, 1)"],
        tooltip: {
          trigger: "axis",
          backgroundColor: 0,
          padding: 0,
          borderWidth: 0,
          formatter(params) {
            let name = params[0].dataIndex == 0 ? '现在' : ''
            let tooltips = 
            `
              <div class="tooltip">
                <p>${name}</p>
                <p>${params[0].axisValue}</p>
                <p>${params[0].data.type || 0}</p>
                <p>${params[0].data.value || 0}%</p>
              </div>
            `;
            return tooltips
          },
        },
        grid: [
          {
            left: '15%',
            right: '5%',
            bottom: '15%',
            top: 40,
            // height: "55%",
          },
        ],
        xAxis: {
          type: "category",
          data: this.xList,

          axisPointer: {
            type: "shadow"
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              type: "solid",
              color: "#3C8BC5", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            color: "#94B4CA", // 刻度样式
          },
          boundaryGap: true,
        },

        yAxis: {
          type: "value",
          name: "剩余泊位占比",
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            padding: [0, -14, 0, 0],
          },
          axisTick: {
            show: false, // y轴坐标点是否消失
          },
          axisLine: {
            // y轴
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "rgba(255, 255, 255, 0.3000)", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#94B4CA",
            },
            formatter: `{value}%`,
          },
        },
        // dataZoom: [
        //   {
        //     type: "slider",
        //     show: true,
        //     height: 4,
        //     left: "1%",
        //     right: "1%",
        //     bottom: "0",
        //     start: 0,
        //     end: 100,
        //     filterMode: 'empty',
        //     fillerColor: "rgba(78, 108, 167, 1)",
        //     backgroundColor: "rgba(41, 62, 103, 1)",
        //     borderColor: "none",
        //     textStyle: {
        //       color: "#d4ffff",
        //       fontSize: 11,
        //       border: "none",
        //     },
        //   },
        //   {
        //     type: "inside",
        //   },
        // ],
        series: [
          {
            name: "停车收入",
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(164, 196, 255, 0.7)", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(131, 168, 255, 0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#A4B8E3",
                  width: 1,
                },
              },
            },
            barWidth: 20,
            data: this.dataList
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
    });

    window.addEventListener("resize", () => {
      this.echartsDOM.resize();
    });
  },
};
</script>
<style>
</style>
<style lang="stylus" scoped>
.content {
  padding: 0 16px;
  position relative
  height: 100%;
  .leftberthForecast {
    padding-top: 16px;
    position absolute
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    >>> .tooltip {
      width: 90px;
      background: rgba(191,212,255,0.19);
      border-radius: 4px;
      backdrop-filter: blur(10px);
      color: rgba(214, 243, 255, 1)
      line-height: 25px;
      padding-top: 5px;
      P {
        padding-left: 15px;
      }
    }
  }
}
</style>
