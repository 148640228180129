<!--
 * @Author: DZM
 * @Date: 2022-03-28 14:59:08
 * @LastEditors: lijing
 * @LastEditTime: 2022-04-12 14:15:33
 * @Description: 
-->
<template>
  <!-- 客诉类型分析 -->
    <div class="title">
      <!-- <el-row :span="24">
        <el-col :span="8">
          <span class="title_span">{{ title }}</span>
          <tips :tip="tip" />
        </el-col>
        <el-col :span="16">
          <ul class="selcetTimeRange">
            <li
              v-for="(item, index) in typeList"
              :key="index"
              :class="activeLI == item.type ? 'activeli' : 'normalli'"
              @click="selcetTimeRange(item.type)"
            >
              {{ item.name }}
            </li>
          </ul>
        </el-col>
      </el-row> -->
      <el-row :span="24">

        <div style="float:left;">
          <span class="title_span">{{ title }}</span>
          <tips :tip="tip" />
        </div>

        <div style="float:right;">
          <ul class="selcetTimeRange">
            <li
              v-for="(item, index) in typeList"
              :key="index"
              :class="activeLI == item.type ? 'activeli' : 'normalli'"
              @click="selcetTimeRange(item.type)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </el-row>
    </div>
</template>

<script>
import tips from './tip.vue'
export default {
  props: ['title', 'tip'],
  name: "timeType",
  data() {
    return {
      activeLI: 0,
      typeList: [
        { name: '今日', type: 0 },
        { name: '近7日', type: 3 },
        { name: '近30日', type: 4 },
        { name: '近12月', type: 15 }
      ]
    };
  },
  components: {
    tips
  },
  created() {},
  mounted() {
  },
  methods: {
    selcetTimeRange(num, date) {
      this.activeLI = num;
      let timeArr = [this.$TimeAll(num)[0], this.$TimeAll(num)[1], num];
      this.$emit('getTimeType', timeArr)
    }
  },
};
</script>

<style lang="stylus" scoped>

  .title {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #E7F8FF;
    padding-left: 20px;
    background: url('./img/tip-bottom.png') no-repeat center bottom;
    background-size: 100%;
    .title_span {
      font-size: 14px;
    }
  }

  .selcetTimeRange {
   
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 16px;
    height: 18px;
    font-weight: normal;
    text-align: right;

    li {
      font-size: 12px;
      cursor: pointer;
      display: inline-block;
      margin-left: 4px;
      height: 100%;
      line-height: 18px;
      padding: 0 8px;
    }

    .normalli {
      color: #DCDCDC;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }

    .activeli {
      color: #FFFFFF;
      background: linear-gradient(180deg, rgba(0, 255, 253, 0.3) 0%, rgba(0, 255, 250, 0) 100%);
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(14, 255, 253, 1), rgba(6, 255, 251, 0)) 1 1;
      border-bottom: none;
    }
  }
</style>
