<template>
  <!-- 客诉类型分析 -->
  <div class="chartsContent-wrap">
    <timeType
      @getTimeType="getTimeType"
      :tip="'统计范围内用户对车场进行投诉反馈的不同类型占比'"
      :title="'客诉类型分析'"
    ></timeType>
    <div class="leftThreeChart" id="leftThreeChart1Dp"></div>
  </div>
</template>

<script>
import timeType from "../componets/timeType.vue";
export default {
  name: "overviewParkBusiness",
  data() {
    return {
      activeLI: 1,
      echartsDOM: null,
      info: {},
      startDate: "",
      endDate: "",
      tableData: [0],
      total: 0,
      type: 0,
    };
  },
  components: {
    timeType,
  },
  computed: {
    getRegionalCollection() {
      return this.$store.state.regionalCollection;
    },
  },
  watch: {
    getRegionalCollection: {
      handler(newVal) {
        this.info = newVal;
        // this.getData(newVal);
      },
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
      this.getData(this.$store.state.regionalCollection);
      // this.initCharts();
    });
  },
  methods: {
    // 获取数据
    getData(data) {
      this.tableData = [];
      this.total = 0;
      this.$api.rightSideApi
        .queryMemberAdviseByType({
          dateType: this.type != 15 ? 1 : 2,
          areaId: data.areaId,
          dataSource: data.dataSource,
          startDate: this.startDate || this.$TimeAll(0)[0],
          endDate: this.endDate || this.$TimeAll(0)[1],
        })
        .then((res) => {
          let arr = res.value;
          if (res.value.length > 0) {
            this.tableData = arr.map((val) => {
              this.total += val.adviseCnt;
              return {
                name: val.adviseTypeDesc,
                value: val.adviseCnt,
              };
            });
          } else {
            this.tableData = [0];
          }
          this.initCharts();
        });
    },
    // 获取时间
    getTimeType(date) {
      this.startDate = date[0];
      this.endDate = date[1];
      this.type = date[2];
      this.getData(this.info);
    },
    initCharts() {
      var that = this;
      var chartDom = document.getElementById("leftThreeChart1Dp");
      var myChart = this.$echarts.init(chartDom);
      this.echartsDOM = myChart;
      var option = {
        title: {
          text: `${that.total}次`,
          // 副标题
          subtext: `客诉总数`,
          // 主副标题间距
          itemGap: 10,
          x: "18%",
          y: "center",
          top: "35%",
          // 主标题样式
          textStyle: {
            fontSize: this.fontSize(1),
            color: "#ffffff",
          },
          // 副标题样式
          subtextStyle: {
            fontSize: this.fontSize(0.6),
            color: "#ffffff",
            align: "center",
          },
          textAlign: "center",
        },
        color: [
          "rgba(105, 223, 228, 1)",
          "rgba(62, 138, 228, 1)",
          "rgba(119, 134, 239, 1)",
          "rgba(99, 218, 153, 1)",
          "rgba(204, 215, 98, 1)",
          "rgba(212, 119, 119, 1)",
          "rgba(216, 229, 224, 1)",
        ],
        tooltip: {
          trigger: "item",
          formatter(params) {
            let tip = `
              ${params.marker} ${params.name} ${params.value} 次 ${params.percent} %
            `;
            return tip;
          },
        },
        legend: {
          orient: "vertical",
          // top: '20%',
          y: "center",
          itemGap: 19,
          left: "40%",
          bottom: "15%",
          height: "60%",
          itemWidth: 3,
          itemHeight: 11,
          textStyle: {
            color: "#D8E9FF",
            fontSize: 12,
          },
        },
        textStyle: {
          // 图例文字的样式
          color: "#ADADAD",
          fontSize: 12,
        },
        series: [
          {
            name: "",
            type: "pie",
            // radius: ["57%", "63%"],
            radius: ["70%", "77%"],
            center: ["20%", "50%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
                // color: '#4c4a4a',
                formatter: "{a|" + 200 + "}" + "{b|" + "次" + "}" + "\n\r" + "{c|评价总分}",

                rich: {
                  a: {
                    color: "#fff",
                    fontSize: 22,
                    lineHeight: 40,
                    textDecoration: "underline",
                  },
                  b: {
                    color: "#fff",
                    fontSize: 12,
                    foneWeight: "bold",
                  },
                  c: {
                    color: "#fff",
                    fontSize: 12,
                  },
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.tableData,
          },
        ],
      };
      // 图例改变
      myChart.on("legendselectchanged", (params) => {
        this.setOption(myChart, params);
      });
      // 鼠标滑过
      // myChart.on("mouseover", (params) => {
      //   this.actionMouseOn(myChart, params);
      // });

      myChart.setOption(option);

      window.addEventListener("resize", () => {
        myChart.resize();
        setTimeout(() => {
          this.initCharts();
        }, 2000);
      });
    },
    fontSize(res) {
      let clientWidth = document.getElementById("leftThreeChart1Dp").clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return res * fontSize;
    },
    actionMouseOn(myChart, params) {
      // currName = params.value;
      let op = myChart.getOption();
      let _label = {
        normal: {
          show: true,
          position: "center",
          formatter: [`{a|${params.value}}`, `{b|${params.name}}`].join("\n"),
          rich: {
            a: {
              color: "#fff",
              fontSize: 18,
              lineHeight: 30,
            },
            b: {
              color: "#fff",
              fontSize: 12,
              foneWeight: "bold",
            },
          },
        },
      };
      if (params.seriesIndex === 1) {
        _label.normal.formatter = ["zhiliang"].join("\n");
      }
      op.series[0].label = _label;
      myChart.setOption(op, true);
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartsContent-wrap {
  margin-top: 16px;
  padding: 0 16px;

  .leftThreeChart {
    padding-top: 16px;
    // height: 17vh;
    height: 160px
  }
}
</style>
