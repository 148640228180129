<!--
 * @Author: DZM
 * @Date: 2022-04-12 14:09:01
 * @LastEditors: faf
 * @LastEditTime: 2022-04-14 14:37:59
 * @Description: 
-->
<template>
  <el-tooltip class="item" :visible-arrow="false" popper-class="atooltip" effect="dark" placement="top-start">
    <div slot="content" v-html="tip"></div>
    <img class="title_img" src="./img/icon.png" alt="">
  </el-tooltip>
</template>
<script>
export default {
  props: ['tip']
}
</script>
<style>
  .atooltip {
    background: rgba(191, 212, 255, 0.30)!important;
  }
</style>
<style scoped>
  .title_img {
    width: 13px;
    height: 13px;
    padding-left: 5px;
    margin-bottom: 3px;
    vertical-align: middle;
    z-index: 2;
  }
</style>
