<!--
 * @Author: DZM
 * @Date: 2022-08-16 13:58:04
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-24 18:08:21
 * @Description: 
-->
<template>
  <div class="content">
    <el-row>
      <el-col :span="12" style="padding-right: 15px;">
          <turnover :dataList="dataList"></turnover>
      </el-col>
      <el-col :span="12" style="padding-right: 15px;">
          <utilization :dataList="dataList"></utilization>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import turnover from './child/turnover.vue'
import utilization from './child/utilization.vue'
export default {
  components: {turnover, utilization},
  data() {
    return {
      dataList: []
    }
  },
  computed: {
    getnewRegionalCollection() {
      return this.$store.state.newregionalCollection;
    }
  },
  watch: {
    getnewRegionalCollection: {
      handler(newVal) {
        // console.log(newVal, '----------------------')
        this.getData(newVal);
      }
    }
  },
  methods: {
    getData(data) {
      this.dataList = [];
      this.$api.rightSideApi.parkUsingRatio({
        parkId: data.parkId
      }).then(res => {
        if (res.state == 0) {
          this.dataList = res.value
        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
</style>
