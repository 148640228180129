// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/tip-bottom.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".chartsContent-wrap[data-v-5f3638ec] {\n  margin-top: 16px;\n  padding: 0 16px;\n}\n.chartsContent-wrap .title[data-v-5f3638ec] {\n  height: 20px;\n  font-size: 14px;\n  font-family: PingFangSC-Semibold, PingFang SC;\n  font-weight: 600;\n  color: #e7f8ff;\n  padding-left: 20px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center bottom;\n  background-size: 100%;\n}\n.chartsContent-wrap .turnoverAnlysisDp[data-v-5f3638ec] {\n  height: 264px;\n}\n", ""]);
// Exports
module.exports = exports;
