<!--
 * @Author: DZM
 * @Date: 2022-03-23 18:16:39
 * @LastEditors: lijing
 * @LastEditTime: 2022-09-05 17:22:24
 * @Description: 
-->
<template>
<div class="bg">
  <div class="leftChartsContent">
      <div class="chartsContent">
        <div class="flexItem">
          <info-child></info-child>
        </div> 
      <div class="flexItem">
        <berth-forecast></berth-forecast>
      </div>
      </div>
  </div>
</div>
</template>

<script>
import infoChild from './child/info';
import berthForecast from './child/berthForecast'
export default {
  name: "newleftCharts",
  data() {
    return {
      map: "",
    };
  },
  components: {infoChild, berthForecast},
  methods: {
   
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
    });
  },
};
</script>

<style lang="stylus" scoped>
.chartsContent {
  display: flex;
  flex-direction:column;
  height: 100%;
  .flexItem{
    flex:1;
  }
}
.bg {
  position: absolute;
  top: 46px;
  left: 16px;
  background: linear-gradient(270deg, rgba(0,5,32,0) 0%, rgba(0,5,32,0.6) 34%, rgba(0,5,32,0.8) 100%);
  z-index: 1100;
  height: 93%;
  width: 480px;
}
.leftChartsContent {
  position: absolute;
  // top: 46px;
  // left: 16px;
  z-index: 999;
  height: 100%;
  width: 440px;
  // min-width: 200px;
  background: linear-gradient(180deg, rgba(182, 214, 255, 0.1) 0%, rgba(132, 173, 255, 0.19) 100%);
  backdrop-filter: blur(5px);
}
</style>
