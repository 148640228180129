<template>
  <!-- 停车业务概览 -->
  <div class="chartsContent-wrap">
    <div class="title">停车业务概览</div>
    <div class="overviewItemContent marginbottom10">
      <div class="overviewItem">
        <img class="imgstyle" src="../../images/tip-parktotal.png" alt="" />
        <p class="overviewtitle">车场总数</p>
        <p class="overviewnum">{{ infoDetail.parkCount || 0 }}</p>
        <div class="borderright"></div>
      </div>
      <div class="overviewItem">
        <img class="imgstyle" src="../../images/tip-berthstotal.png" alt="" />
        <p class="overviewtitle">泊位总数</p>
        <p class="overviewnum">{{ infoDetail.berthCount || 0 }}</p>
        <div class="borderright"></div>
      </div>
      <div class="overviewItem">
        <img class="imgstyle" src="../../images/tip-todayincome.png" alt="" />
        <p class="overviewtitle">今日收入</p>
        <!-- <p class="overviewnum">{{ infoDetail.todaysIncome | moneyHandling }}<span class="unit">元</span></p> -->
        <p class="overviewnum">
          {{ infoDetail.todaysIncome | keepTwoDecimalFull
          }}<span class="unit">{{ infoDetail.todaysIncome | GetUnit }}</span>
        </p>
        <div class="borderright"></div>
      </div>
      <div class="overviewItem">
        <img class="imgstyle" src="../../images/tip-totalincome.png" alt="" />
        <p class="overviewtitle">累计收入</p>
        <p class="overviewnum">
          {{ infoDetail.accumulativeIncome | keepTwoDecimalFull
          }}<span class="unit">{{ infoDetail.accumulativeIncome | GetUnit }}</span>
        </p>
      </div>
    </div>
    <div class="borderline"></div>
    <div class="overviewItemContent">
      <div class="overviewItem">
        <img class="imgstyle" src="../../images/tip-todayserver.png" alt="" />
        <p class="overviewtitle">今日服务车次数</p>
        <p class="overviewnum">{{ infoDetail.parkRecordCount || 0 }}</p>
        <div class="borderright"></div>
      </div>
      <div class="overviewItem">
        <img class="imgstyle" src="../../images/tip-brtthsuser.png" alt="" />
        <p class="overviewtitle">近30日泊位利用率</p>
        <p class="overviewnum">
          {{ infoDetail.averageDailyBerthUtilizationRate || 0 }} <span class="unit">%</span>
        </p>
        <div class="borderright"></div>
      </div>
      <div class="overviewItem">
        <img class="imgstyle" src="../../images/tip-berthsturnover.png" alt="" />
        <p class="overviewtitle">近30日泊位周转率</p>
        <p class="overviewnum">
          {{ infoDetail.averageDailyBerthTurnover || 0 }}<span class="unit">次</span>
        </p>
        <div class="borderright"></div>
      </div>
      <div class="overviewItem">
        <img class="imgstyle" src="../../images/tip-registmember.png" alt="" />
        <p class="overviewtitle">注册会员</p>
        <p class="overviewnum">{{ infoDetail.totalMemberCount || 0 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props:{
  //   allData
  // },
  name: "overviewParkBusiness",
  data() {
    return {
      /**
       * parkCount 车场总数 berthCount 泊位数量 todaysIncome 今日收入
       * accumulativeIncome 累计收入 averageDailyBerthUtilizationRate 近30日泊位利用率
       * averageDailyBerthTurnover 近30日泊位周转率 parkRecordCount 今日服务车次数
       * totalMemberCount 注册会员
       */
      infoDetail: {
        parkCount: "",
        berthCount: "",
        todaysIncome: "",
        accumulativeIncome: "",
        averageDailyBerthUtilizationRate: "",
        averageDailyBerthTurnover: "",
        parkRecordCount: "",
        totalMemberCount: "",
      },
    };
  },
  components: {},
  computed: {
    getRegionalCollection() {
      return this.$store.state.regionalCollection;
    },
  },
  watch: {
    getRegionalCollection: {
      handler(newVal) {
        console.log(newVal, "----------------------");
        this.getParkBerthData(newVal);
        this.getTodayIncome(newVal);
        this.getSummary(newVal);
        this.getNumberOfServiceToday(newVal);
        // this.getTotalMemberCount(newVal);
      },
    },
  },
  methods: {
    // 获取车场，泊位数据
    getParkBerthData(data) {
      this.$api.leftSideApi
        .parkingTotal({
          areaId: data.areaId,
          dataSource: data.dataSource,
        })
        .then((res) => {
          let data = res.value;
          this.infoDetail.parkCount = data.parkCount;
          this.infoDetail.berthCount = data.berthCount;
        });
    },
    // 获取今日收入
    getTodayIncome(data) {
      this.$api.leftSideApi
        .todayIncomeAmount({
          areaId: data.areaId,
          dataSource: data.dataSource,
        })
        .then((res) => {
          let data = res.value;
          this.infoDetail.todaysIncome = data;
        });
    },
    // 获取累计收入
    getSummary(data) {
      this.$api.leftSideApi
        .totalRevenue({
          areaId: data.areaId,
          dataSource: data.dataSource,
        })
        .then((res) => {
          let data = res.value;
          this.infoDetail.accumulativeIncome = data.accumulativeIncome;
          this.infoDetail.averageDailyBerthUtilizationRate = data.averageDailyBerthUtilizationRate;
          this.infoDetail.averageDailyBerthTurnover = data.averageDailyBerthTurnover;
        });
    },
    // 获取今日服务车次数
    getNumberOfServiceToday(data) {
      this.$api.leftSideApi
        .NumberOfServiceToday({
          areaId: data.areaId,
          dataSource: data.dataSource,
        })
        .then((res) => {
          let data = res.value;
          this.infoDetail.parkRecordCount = data.parkRecordCount;
        });
    },
    // 获取注册会员
    getTotalMemberCount(data) {
      this.$api.leftSideApi
        .totalMemberCount({
          areaId: data.areaId,
          dataSource: data.dataSource,
        })
        .then((res) => {
          let data = res.value;
          this.infoDetail.totalMemberCount = data;
        });
    },
    getWid_Hei() {
      let width, height;
      if (window.innerWidth) {
        width = window.innerWidth;
        height = window.innerHeight;
        console.log("1", width, height);
      } else if (document.compatMode === "BackCompat") {
        width = document.body.clientWidth;
        height = document.body.clientHeight;
        console.log("2", width, height);
      } else {
        width = document.documentElement.clientWidth;
        height = document.documentElement.clientHeight;
        console.log("3", width, height);
      }

      return {
        w: width,
        h: height,
      };
    },
    // 根据获取分辨率计算画布压缩比例
    restScale() {
      let { w, h } = this.getWid_Hei();
      console.log(w, h, "------------------------");
      let overviewtitle = document.querySelectorAll(".overviewtitle");
      if (w < 1920) {
        overviewtitle.forEach((dom, index) => {
          console.log(dom, "-----------------------");
          dom.style.transform = "scale(" + w / 1920 + ")";
          if (index == 5 || index == 6) {
            dom.style.transformOrigin = "10%";
          }
        });
      }
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {});
    window.onresize = () => {
      this.restScale();
    };
    this.restScale();

    this.getTotalMemberCount(this.$store.state.regionalCollection);
  },
};
</script>

<style lang="stylus" scoped>
.chartsContent-wrap {
  padding: 16px;
  .unit{
    font-size:12px !important;
  }
  .title {
    height: 20px;
    // line-height:20px;
    box-sizing: content-box;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #E7F8FF;
    padding-left: 20px;
    background: url('../../images/tip-bottom.png') no-repeat center bottom;
    background-size: 100%;
  }
  .borderline{
    border-bottom:1px solid #2E516D;
  }
  .marginbottom10{
     margin-bottom: 10px;
  }
  .overviewItemContent {
    display: flex;
    justify-content: space-between

    .overviewItem {
      margin-top:10px;
      // flex: 1;
      width: 102px;
      height: 102px;
      position: relative;
      text-align: center;

      .borderright {
        position: absolute;
        top: 20px;
        right: 0;
        height: 60px;
        border-right: 1px solid #2E516D;
      }

      .noborder {
        border: none;
      }

      .imgstyle {
        width: 44px;
      }

      .overviewtitle {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C8EDFF;
        white-space: nowrap;
        padding: 4px 0 7px;
        line-height: 17px;
        font-size: 12px;
        opacity: 0.7;
      }

      .overviewnum {
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #DEF6FF;
        line-height: 24px;
        white-space: nowrap;
      }
    }
  }

  .overviewItems {
    padding: 5px 0;
    text-align: center;
    fong-size: 12px;

    .imgstyle {
      width: 40%;
    }

    .overviewItem {
      // height: 12.5vh;
      height: 102px
      padding: 20px 0 11px;
    }

    .borderBottom {
      border-bottom: 2px solid #2E516D;
      position: relative;
    }

    .borderBottomNone {
      border-bottom: none;
    }

    .borderright {
      position: absolute;
      right: 0;
      height: 60%;
      width: 2px;
      vertical-align: middle;
      border-right: 2px solid #2E516D;
    }

    .overviewtitle {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #C8EDFF;
      white-space: nowrap;
      padding: 4px 0 7px;
      font-size: 12px;
    }

    .overviewnum {
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #DEF6FF;
      line-height: 24px;
    }
  }
}
</style>
