<template>
  <div class="summarizePark">
    <div class="summarizeParkDetail" v-if="parkDetailValue">
      <h2 class="parkTitle">
            <span v-text="parkDetailValue.parkName">中关村大厦停车场</span>
      </h2>
      <p class="parkAddress" :title="parkDetailValue.parkAddress">
        <i class="parkImgIcon"></i> {{ parkDetailValue.parkAddress | AddressLength }}
      </p>
      <div class="parkImg">
        <img v-if="parkDetailValue.parkImg" :src="parkDetailValue.parkImg" alt="" />
      </div>
      <p class="t_r" style="line-height: 30px;margin-top:12px">
        <label>泊位占用</label>
        <span>
          <span v-text="parkDetailValue.occupyAmount">13</span>/<span
            v-text="parkDetailValue.amount"
            >18</span
          >&nbsp;
          <span v-if="!parkDetailValue.amount || parkDetailValue.amount == 0">0%</span>
          <span v-else
            >{{
              ((parkDetailValue.occupyAmount / parkDetailValue.amount) * 100) | numFilter
            }}%</span
          >
        </span>
        <br  class="brLine"/>
        <span class="getParkList" @click="getParkList(parkDetailValue.parkId)">泊位实况</span>
        <span
          class="getParkList"
          @click="videoMonitor(parkDetailValue.parkId)"
          v-show="videoList.length > 0"
          >视频监控</span
        >
      </p>

      <p class="t_r">
        <label>计费规则</label><span v-text="parkDetailValue.scopeName">一类区计费</span>
      </p>
      <p class="t_r" style="border: none;">
        <label>商户</label
        ><span v-text="parkDetailValue.operationName" style="padding-left:30px">北京石景安停车管理有限公司</span>
      </p>
    </div>
    <div class="summarizeParkDialogWrapper">
      <el-dialog
          class="summarizeParkDetaiDialog"
          :class="{isfull:isfull, 'mask': isShow}"
        ref="dialogVisible"
        :title="parkDetailValue.parkName"
        :visible.sync="dialogVisible"
        :modal="false"
        append-to-body
        center
        style="z-index: 4000;"
      >
        <GeminiScrollbar class="parkRecordList" v-if="parkRecordList.length > 0">
          <ul>
            <li
              v-for="item in parkRecordList"
              @click="getParkRecord(item.parkRecordId)"
              :class="{ noCarIcon: !item.plateNumber }"
              :key="item.plateNumber"
              :style="{
                background:
                  item.carType == 0
                    ? 'url(' + iconCar + ')no-repeat'
                    : item.carType == 1
                    ? 'url(' + trucks + ')no-repeat'
                    : item.carType == 2
                    ? 'url(' + truck + ')no-repeat'
                    : 'url(' + noCar + ')no-repeat'
              }"
            >
              <!-- <img src="./vedio2@2x.png" alt="" class="tips"> -->
              <span
                class="plateNumber"
                :style="plateNumberColor(item.plateColor)"
                v-if="item.plateNumber"
              >
                <span>{{ item.plateColor }}</span
                >&nbsp;
                <span>{{
                  /无/g.test(item.plateNumber) ? "无牌车" : item.plateNumber
                }}</span>
              </span>
              <span class="plateNumber free" v-else>车位空闲</span><br />
              <span class="berthCode" v-text="item.berthCode">berthCode</span>
            </li>
          </ul>
        </GeminiScrollbar>
        <p v-else class="noParkRecordList">暂无数据</p>
        <el-dialog
          class="innerVisible"
          :class="{'mask': isShow1}"
          :title="parkDetailValue.parkName"
          :modal="false"
          center
          :visible.sync="innerVisible"
          :before-close="handleClose"
        >
          <div class="parkDeatail">
            <el-row :gutter="24">
              <el-col :span="8">
                <div class="detail1"><span class="icon_image"></span><span class="detail_name">商户：</span>{{ parkRecordDetail.operationName }}</div>
              </el-col>
               <el-col :span="8">
                <div class="detail1"><span class="icon_image"></span><span class="detail_name">泊位号：</span>{{ parkRecordDetail.berthCode }}</div>
              </el-col>
              <el-col :span="8">
                <div class="detail1"><span class="icon_image"></span><span class="detail_name">入场时间：</span>{{ parkRecordDetail.strEntryTime }}</div>
              </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                  <div class="detail1"><span class="icon_image"></span><span class="detail_name">停车时长：</span>{{ parkRecordDetail.parkTime }}</div>
                </el-col>
              <el-col :span="8">
                <div class="detail1">
                  <span class="icon_image"></span><span class="detail_name">车牌号：</span>{{
                    /无/g.test(parkRecordDetail.plateNumber)
                      ? "无牌车"
                      : parkRecordDetail.plateNumber
                  }}
                </div>
              </el-col>
              <el-col :span="8">
                <div class="detail1">
                  <span class="icon_image"></span><span class="detail_name">出场时间：</span><span class="detail_size"
                    :style="{ color: parkRecordDetail.strExitTime ? '#fff' : '#FA2256' }"
                    >{{
                      parkRecordDetail.strExitTime ? parkRecordDetail.strExitTime : "等待出场..."
                    }}</span
                  >
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <div class="detail1">
                  <span class="icon_image"></span><span class="detail_name">实收金额：</span>{{
                    parkRecordDetail.actualPay ? parkRecordDetail.actualPay / 100 + "元" : "0元"
                  }}
                </div>
              </el-col>
              <el-col :span="16">
                <div class="detail1"><span class="icon_image"></span><span class="detail_name">停车场：</span>{{ parkRecordDetail.parkName }}</div>
              </el-col>
            </el-row>
          </div>
          <!-- 入场 -->
          <div v-if="entryPic.length" class="pic-head">
            <div class="pic-title">
              <!-- <div class=""></div> -->
              <span class="icon_entry_image"></span>
              入场照片
            </div>
            <div
              class="picBox"
              v-for="value in entryPic"
              v-show="value.imageType != 22"
              :key="value.accessKey"
            >
              <div class="title">
                {{
                  value.imageType == 1
                    ? "全景照片"
                    : value.imageType == 2
                    ? "车辆照片"
                    : value.imageType == 3
                    ? "车牌照片"
                    : ""
                }}
              </div>
              <div class="pic">
                <div
                  v-if="value.imageType == 1"
                  :style="{
                    width: '100%',
                    height: '100%',
                    background: 'white url(' + value.picUrl + ') center/contain no-repeat'
                  }"
                ></div>
                <img v-if="value.imageType != 1" :src="value.picUrl" width="100%" height="100%" />
              </div>
            </div>
            <div style="clear:both"></div>
          </div>
          <!-- 出场 -->
          <div v-if="exitPic.length" class="pic-head">
            <div class="pic-title">
               <span class="icon_entry_image"></span>
               出场照片</div>
            <div
              class="picBox"
              v-for="value in exitPic"
              v-show="value.imageType != 22"
              :key="value.accessKey"
            >
              <div class="title">
                {{
                  value.imageType == 1
                    ? "全景照片"
                    : value.imageType == 2
                    ? "车辆照片"
                    : value.imageType == 3
                    ? "车牌照片"
                    : ""
                }}
              </div>
              <div class="pic">
                <div
                  v-if="value.imageType == 1"
                  :style="{
                    width: '100%',
                    height: '100%',
                    background: 'white url(' + value.picUrl + ') center/contain no-repeat'
                  }"
                ></div>
                <img v-if="value.imageType != 1" :src="value.picUrl" width="100%" height="100%" />
              </div>
            </div>
            <div style="clear:both"></div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false; isShow = true;">返 回</el-button>
          </span>
        </el-dialog>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false;">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
    <monitor-details
      ref="monitorDetails"
      :monitorList="videoList"
      :monitorParkName="parkRecordDetail.parkName"
    />
  </div>
</template>

<script>
// 无noCar_icon.png
import noCar from "../images/noCar_icon.png";
// 小车
import iconCar from "../images/icon_car.png";
// 中型车
import trucks from "../images/trucks.png";
// 大型车
import truck from "../images/truck.png";
// 车场监控列表
import MonitorDetails from "@/views/summarize/components/monitorDetails"

export default {
  props: ["parkDetailValue", "videoList"],
  data() {
    return {
      isfull: false,
      isShow: false,
      isShow1: false,
      noCar: noCar,
      iconCar: iconCar,
      trucks: trucks,
      truck: truck,
      innerVisible: false,
      dialogVisible: false,
      parkRecordList: [],
      parkRecordDetail: {},
      exitPic: [],
      entryPic: []
    };
  },
  created() {},
  watch: {
    dialogVisible: {
      handler(newVal, oldVal) {
        this.$EventBus.$emit("mapShowFn", !newVal);
      }
    }
  },
  filters: {
    numFilter(a) {
      return a.toFixed(1);
    },
    filtersLength(val, c, len) {
      if (val.length > 6) {
        return val.slice(0, 6) + "...";
      }
      return val;
    },
    AddressLength(val) {
      if (val == undefined) {
      } else {
        if (val.length > 15) {
          return val.slice(0, 15) + "...";
        }
        return val;
      }
    },
    plateNumFilterJing(val) {
      let len = val.length - 1;
      let reg = /[\u4E00-\u9FA5]/;
      if (reg.test(val[len])) {
        return val.slice(val.length - 1);
      }
      return "";
    },
    plateNumFilter(val) {
      let len = val.length - 1;
      let reg = /[\u4E00-\u9FA5]/;
      if (reg.test(val[len])) {
        return val.slice(0, val.length - 1);
      }
      return val;
    },
    plateNumFilterTwo(val, c, len) {
      if (val.length > len) {
        return val.slice(0, len) + "...";
      }
      return val;
    }
  },
  mounted() {
    this.$EventBus.$on("isfullSetDialogWidth", bool => {
      this.isfull = bool
    });
  },
  methods: {
    plateNumberColor(color) {
      let plateNumberColor = "";
      let textColor = "#fff";
      if (color == "蓝") {
        plateNumberColor = "#1580E6";
      } else if (color == "黄") {
        plateNumberColor = "#F5BC1F";
        textColor = "#27272E";
      } else if (color == "白") {
        plateNumberColor = "#FFFFFF";
        textColor = "#333";
      } else if (color == "黑") {
        plateNumberColor = "#000000";
      } else if (color == "绿") {
        plateNumberColor = "#37B2A1";
      } else if (color == "黄绿") {
        plateNumberColor = "linear-gradient(270deg,#37B2A1, #37B2A1 67%, #F5BC1F 67%, #F5BC1F )";
      } else {
        textColor = "#333";
      }
      let styleObj = "background: " + plateNumberColor + ";" + "color:" + textColor;
      return styleObj;
    },
    getEntryPicture(oprNum) {
      if (!oprNum) return;
      this.$axios.get("/acb/2.0/parkRecord/picture/" + oprNum).then(res => {
        this.entryPic = res.value;
      });
    },
    getExitOprPicture(oprNum) {
      if (!oprNum) return;
      this.$axios.get("/acb/2.0/parkRecord/picture/" + oprNum).then(res => {
        this.exitPic = res.value;
      });
    },
    getParkRecord(parkRecordId) {
      if (!parkRecordId) {
        return false;
      }
      this.parkRecordDetail = {};
      this.entryPic = [];
      this.exitPic = [];
      this.innerVisible = true;
      this.isShow1 = true;
      this.isShow = false;
      this.$axios.get("/acb/2.0/parkRecord/" + parkRecordId).then(res => {
        this.parkRecordDetail = res.value;
        this.getEntryPicture(this.parkRecordDetail.entryOprNum);
        this.getExitOprPicture(this.parkRecordDetail.exitOprNum);
      });
    },
    getParkList(parkId) {
      this.dialogVisible = true;
      this.isShow = true;
      this.isShow1 = false;
      this.$axios
        .get("/acb/2.0/map/getRealtimeBerthInfo", {
          data: {
            parkId
          }
        })
        .then(res => {
          if (res.value.length > 0) {
            this.parkRecordList = res.value;
          } else {
            this.parkRecordList = [];
          }
        });
    },
    // 视频监控
    videoMonitor(data) {
      this.$refs.monitorDetails.dialogVisible = true
      // console.log(this.videoList)
      // window.open(
      //   "/video.html?" +
      //     this.videoList[0].ipAddress +
      //     "/" +
      //     this.videoList[0].ipPort +
      //     "/" +
      //     this.videoList[0].account +
      //     "/" +
      //     this.videoList[0].password +
      //     "/" +
      //     this.videoList[0].monitorPort,
      //   "查看监控设备"
      // );
    },
    handleClose(e) {
      this.innerVisible = false;
      this.isShow = true
      // console.log(e);
    }
  },
  components: {
    MonitorDetails
  }
};
</script>
<style lang="stylus">
.summarizeParkDetaiDialog.isfull .el-dialog {
  width: 830px !important;
  height: 593px;
  // margin: 54px auto !important;
}

.summarizePark .v-modal {
  z-index: 4;
}

.summarizeParkDetaiDialog >>>.el-dialog--center .el-dialog__body {
  // padding 24px;
  // padding: 20px 0 14px 20px;
}
.summarizeParkDetaiDialog >>>.el-dialog--center.isfull .el-dialog__body {
  height: 76%;
}
.summarizeParkDetaiDialog .el-dialog__footer {
  background: linear-gradient(180deg, rgba(182, 214, 255, 0.1) 0%, rgba(132, 173, 255, 0.19) 100%);
  backdrop-filter: blur(0px);
  height: 48px;
}
.summarizeParkDetaiDialog .el-dialog__header {
  background-color: #2A427E;
  box-sizing: border-box;
  padding: 8px;
}
.el-dialog__header .el-icon-close 
  font-size: 16px;
  float: right;
  margin-top: -7px;
.summarizeParkDetaiDialog
  .el-dialog--center
    width: 814px;
    border: 1px solid #3E516C;
    border-radius: 4px;
    // height: 590px;
    // margin: 133px 0 50px 530px !important;
    // margin: 133px 535px !important;
    background: #0D203B;
    // height: 60%;
  .el-dialog__body
    color: #fff;
    height: 468px;
    padding: 16px;
    // height: 70%;
  .dialog-footer
    .el-button
      background: #5999FF
      width: 65px;
      height: 28px;
      line-height: 14px;
      font-size: 14px;
      color: #fff;
      border: none;
      border-radius: 2px;
      padding: 0px;
.summarizeParkDetaiDialog
  color: #fff;
  .el-dialog__header
    height: 41px;
    background: linear-gradient(180deg, rgba(182, 214, 255, 0.1) 0%, rgba(132, 173, 255, 0.19) 100%);
    backdrop-filter: blur(0px);
    span
      color: #fff;
      font-size: 16px;
      font-weight: bold;
  .dialog-footer
    button
      background: #0077ED
      color: #fff;
      border: none;
      border-radius: 4px;
.parkRecordList
  overflow: hidden;
  margin-right: -30px;
  // ul
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-around;
  li.noCarIcon
    background: url('../images/noCar_icon.png') no-repeat #3b4b61;
    background-position: center 20px !important;
    cursor: auto;
  li
    cursor: pointer;
    background: url('../images/icon_car.png') no-repeat #3b4b61;
    background-position: center 20px !important;
    background-color: rgba(255,255,255,0.08)!important;
    background-size: 100px 56px !important;
    color: #fff;
    width: 140px;
    height: 140px;
    padding-top: 80px;
    text-align: center;
    float: left;
    margin: 8px;
    // margin-right: 7px;
    // margin-left: 7px;
    // margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid rgba(200, 251, 255, 0.22);;
    border-radius: 4px;
    background: #162744;
    .plateNumber
      display: inline-block;
      border: 1px solid #ffffff;
      background: #9c9c9c;
      margin-top: 6px;
      color: #FFFFFF;
      width: 101px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      border-radius: 4px;
    .free
      border: none;
      span
        font-size: 14px;
    .berthCode
      font-size: 14px;
      display: inline-block;
      margin-top: 8px;
.innerVisible .el-dialog--center
  // width: 786px !important;
.mask
  position: fixed;
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: rgba(0, 6, 13, 0.56);
  backdrop-filter: blur(2px);
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.parkImgIcon
   background: url('../images/location.png');
   background-size: cover;
   width: 8px;
   height: 11px;
   display: inline-block;
   position: relative;
   top: 1px;
   left: 1px;
.noParkRecordList
  line-height: 60px;
  text-align: center;
  font-size: 16px;
  color: #fff;

.dialogParkName
  line-height: 60px;
  font-size: 14px;
  color: #fff;
  margin-top: -40px;
.parkTitle
  font-size: 16px;
  color: #D8D8D8;
  line-height: 22px;
  font-family: MicrosoftYaHei;
  span
    font-size 12px;

.parkAddress
  font-size: 12px;
  color: #fff;
  // padding-left: 26px;
  line-height: 20px;
  margin-bottom: 12px;

.parkImg
  text-align: center;
  height: 148px;
  background: url('../images/car-pic.png') center no-repeat;
  // border: 1px solid #ddd;
  box-sizing: border-box;
  background-size: cover;
  img
    width: 100%;
    height: 100%;

.t_r
  text-align: right;
  position: relative;
  color: #D8D8D8;
  min-height: 42px;
  line-height: 42px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12)
  label
    position: absolute;
    left: 0;
    top: 0
    height: 100%;
    font-size: 14px;
   span
    font-size: 14px;
    color: #DEF6FF;

.getParkList
  color: #00EBEB !important;
  cursor: pointer;
  position: relative;
  top: 0;
  font-size 12px;
  margin-left 10px

.pic
  background: url("../images/car-pic.png") center no-repeat;
  width: 208px;
  height: 118px;
  border: 1px solid #8091A5;
  margin-top: 10px;
  overflow: hidden;

.pic-title {
  // padding-left: 15px;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #D8D8D8;
}

.picBox {
  float: left;
  width: 228px;
  height: 170px;
  margin-right: 20px;
  padding: 9px 8px;
  background: rgba(156, 207, 241, 0.08);
  border: 1px solid rgba(200, 251, 255, 0.22);
  text-align: center;
  box-sizing: border-box;
}

.parkDeatail {
  padding-left: 14px;
  .parkTitle span {
    font-size: 12px;
    text-align: center;
    display: inline-block;
    width: 100px;
    line-height: 30px;
    border: 1px solid #e1e1e1;
    border-bottom: 0;
  }
  .picTitle {
    line-height: 30px;
  }
  .detail1 {
    font-size: 12px;
  }
  .icon_image {
    display: inline-block; 
    background: url('../images/triangle.png') center no-repeat;
    width: 9px;
    height: 9px;
    background-size: cover;
    margin-right: 5px;
  }
  .detail_name {
    display: inline-block;
    width: 60px;
    font-size: 12px;
    margin-right: 14px;
    opacity: 0.65;
  }
  .detail_size{
    font-size: 12px;
  }
  .el-col {
    padding-bottom: 15px !important;
  }
}
.icon_entry_image {
  display: inline-block;
  background: url('../images/entry.png') center no-repeat;
  width: 14px;
  height: 14px;
  background-size: cover;
  margin-right: 2px;
}
.tips {
  width 20px;
  height 20px;
  transform: translate(-54px, -75px);
}
.pic-head 
  padding-left: 14px;
  padding-top: 20px;
</style>
