<template>
  <!-- 车辆进出场信息 -->
  <div class="chartsContent-wrap">
    <div class="title">车辆进出场信息</div>
    <div class="leftThreeChart">
      <vue-seamless-scroll
        :data="tableData"
        :key="tableData.lenght"
        class="seamless-warp"
        :class-option="optionSetting"
      >
        <ul>
          <li v-for="(item, index) in tableData" :key="index">
            <span style="flex: 1">{{ item.time }}</span>
            <span class="paddingLR">{{ item.plateNumber }}</span>
            <span style="padding-right: 20px">{{ item.recordState }}</span>
            <span style="flex: 1" :title="item.parkName">{{
              item.parkName | wordProcessing(5)
            }}</span>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
// import Underline from 'wangeditor/dist/menus/underline';
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "overviewParkBusiness",
  data() {
    return {
      activeLI: 1,
      tableData: [],
    };
  },

  computed: {
    optionSetting() {
      return {
        step: 0.2, // 数值越大速度滚动越快

        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length

        hoverStop: true, // 是否开启鼠标悬停stop

        direction: 1, // 0向下 1向上 2向左 3向右

        openWatch: true, // 开启数据实时监控刷新dom

        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1

        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3

        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    getRegionalCollection() {
      return this.$store.state.regionalCollection;
    },
  },
  components: { vueSeamlessScroll },
  watch: {
    getRegionalCollection: {
      handler(newVal) {
        this.info = newVal;
        this.getData(newVal);
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    selcetTimeRange(num) {
      this.activeLI = num;
    },
    getData(data) {
      this.tableData = [];
      this.$api.rightSideApi
        .parkRecordScroll({
          areaId: data.areaId,
          dataSource: data.dataSource,
        })
        .then((res) => {
          let arr = res.value;
          if (res.value.length > 0) {
            this.tableData = arr;
          } else {
            this.tableData = [];
          }
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.seamless-warp {
  width: 100%;
  height: calc(100% - 16px);
  overflow: hidden;
}

.chartsContent-wrap {
  margin-top: 16px;
  padding: 0 16px;

  .title {
    height: 20px;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #E7F8FF;
    padding-left: 20px;
    background: url('../../images/tip-bottom.png') no-repeat center bottom;
    background-size: 100%;
  }

  .selcetTimeRange {
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: 16px;
    height: 18px;
    font-weight: normal;
    text-align: right;

    li {
      font-size: 12px;
      cursor: pointer;
      display: inline-block;
      margin-left: 8px;
      height: 100%;
      line-height: 18px;
      padding: 0 8px;
    }

    .normalli {
      color: #DCDCDC;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    }

    .activeli {
      color: #FFFFFF;
      background: linear-gradient(180deg, #00FFFD 0%, rgba(0, 255, 250, 0) 100%);
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(14, 255, 253, 1), rgba(6, 255, 251, 0)) 1 1;
      bottom-bottom: none;
    }
  }

  .leftThreeChart {
    overflow: hidden;
    padding-top: 16px;
    // height: 17vh;
    height: 160px

    li {
      display: flex;
      height: 36px;
      padding: 0 10px;
      line-height: 36px;
      color: #D8E9FF;
      font-size: 12px;
    }

    li:nth-of-type(odd) {
      background: linear-gradient(90deg, rgba(16, 40, 73, 0.24) 0%, #122E55 51%, rgba(16, 40, 73, 0.24) 100%);
    }

    .paddingLR {
      padding: 0 10px;
      flex: 1;
    }
  }
}
</style>
