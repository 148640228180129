<!--
 * @Author: DZM
 * @Date: 2022-03-23 18:16:39
 * @LastEditors: lijing
 * @LastEditTime: 2022-03-28 11:03:29
 * @Description: 
-->
<template>
  <div class="leftChartsContentDP">
    <div class="chartsContent">
      <div class="flexItem">
        <overviewParkBusiness></overviewParkBusiness>
      </div>
      <div class="flexItem">
        <turnoverAnalysis></turnoverAnalysis>
      </div>
      <div class="flexItem">
        <leftThreeChart></leftThreeChart>
      </div>
    </div>
  </div>
</template>

<script>
import overviewParkBusiness from "./overviewParkBusiness";
import turnoverAnalysis from "./turnoverAnalysis";
import leftThreeChart from "./leftThreeChart";
export default {
  name: "leftCharts",
  data() {
    return {
      map: "",
    };
  },
  components: { overviewParkBusiness, turnoverAnalysis, leftThreeChart },
  methods: {},
  created() {},
  mounted() {
    this.$nextTick(() => {});
  },
};
</script>

<style lang="stylus" scoped>
.chartsContent {
  display: flex;
  flex-direction:column;
  height: 100%;
  .flexItem{
    flex:1;
  }
}
.leftChartsContentDP {
  position: absolute;
  transform: translateY(-50%)
  // top: 46px;
  top:50%
  left: 16px;
  z-index: 999;
  // height: 93%;
  height: 878px
  width: 440px;
  // min-width: 200px;
  background: linear-gradient(180deg, rgba(182, 214, 255, 0.1) 0%, rgba(132, 173, 255, 0.19) 100%);
  backdrop-filter: blur(5px);
}
</style>
