var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bgLeftDP" }, [
    _c("div", { staticClass: "leftChartsContent" }, [
      _c("div", { staticClass: "chartsContent" }, [
        _c("div", { staticClass: "flexItem" }, [_c("info-child")], 1),
        _c("div", { staticClass: "flexItem" }, [_c("berth-forecast")], 1),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }