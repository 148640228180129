<!--
 * @Author: DZM
 * @Date: 2022-03-23 18:16:39
 * @LastEditors: lijing
 * @LastEditTime: 2022-03-25 14:45:58
 * @Description: 
-->
<template>
  <!--  -->
  <div class="rightChartsContentDP">
    <div class="chartsContent">
      <div class="flexItem">
        <customerComplaintType></customerComplaintType>
      </div>
      <div class="flexItem">
        <serviceEvaluation></serviceEvaluation>
      </div>
      <div class="flexItem">
        <incomeStatistics></incomeStatistics>
      </div>
      <div class="flexItem">
        <entranceExitInfo></entranceExitInfo>
      </div>
    </div>
  </div>
</template>

<script>
import customerComplaintType from "./customerComplaintType"; // 客诉类型分析

import serviceEvaluation from "./serviceEvaluation"; // 车场服务评价
import incomeStatistics from "./incomeStatistics"; // 停车收入统计
import entranceExitInfo from "./entranceExitInfo"; // 车辆进出场信息
export default {
  name: "leftCharts",
  data() {
    return {
      map: "",
    };
  },
  components: { customerComplaintType, serviceEvaluation, incomeStatistics, entranceExitInfo },
  methods: {},
  created() {},
  mounted() {
    this.$nextTick(() => {});
  },
};
</script>

<style lang="stylus" scoped>
.chartsContent {
  // margin: 24px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .flexItem {
    box-sizing: border-box;
    flex: 1;
  }
}

.rightChartsContentDP {
  position: absolute;
  transform: translateY(-50%)
  right: 16px;
  // top: 46px;
  top:50%
  z-index: 999;
  // height: 93%;
  height: 878px
  width: 440px;
  // min-width: 200px;
  background: linear-gradient(180deg, rgba(182, 214, 255, 0.1) 0%, rgba(132, 173, 255, 0.19) 100%);
  backdrop-filter: blur(5px);
}
</style>
