// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/tip-bottom.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".chartsContent-wrap[data-v-dc633050] {\n  margin-top: 0.83333vw;\n  padding: 0 0.83333vw;\n}\n.chartsContent-wrap .title[data-v-dc633050] {\n  height: 1.04167vw;\n  font-size: 0.72917vw;\n  font-family: PingFangSC-Semibold, PingFang SC;\n  font-weight: 600;\n  color: #e7f8ff;\n  padding-left: 1.04167vw;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center bottom;\n  background-size: 100%;\n}\n.chartsContent-wrap .turnoverAnlysis[data-v-dc633050] {\n  height: 26vh;\n}\n", ""]);
// Exports
module.exports = exports;
