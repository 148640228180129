<!--
 * @Author: DZM
 * @Date: 2022-03-28 14:59:08
 * @LastEditors: lijing
 * @LastEditTime: 2022-08-17 13:53:07
 * @Description: 
-->
<template>
    <div class="title" :style="{background: `url(${title == '周转率' ? bg2 : title == '利用率' ? bg2 : bg1}) no-repeat center bottom`, 'background-size': '100% 100%'}">
      <el-row :span="24">
        <div style="float:left;">
          <span class="title_span">{{ title }}</span>
          <!-- <tips :tip="tip" /> -->
        </div>
      </el-row>
    </div>
</template>

<script>
export default {
  props: ['title'],
  name: "timeType",
  data() {
    return {
      bg1: require('./img/title_url.png'),
      bg2: require('./img/title_url2.png')
    };
  },
  components: {
    // tips
  },
  created() {},
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="stylus" scoped>

  .title {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #E7F8FF;
    padding-left: 20px;
    background-size: 100% 100%;
    .title_span {
      font-size: 14px;
    }
  }
</style>
