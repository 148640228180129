<template>
  <!-- 车场服务评价 -->
  <div class="chartsContent-wrap">
    <timeType @getTimeType="getTimeType" :tip="'统计范围内用户对车场进行评价的不同分值占比'" :title="'车场服务评价'"></timeType>
    <div class="leftThreeChart" id="serviceEvalution"></div>
  </div>
</template>

<script>
import timeType from '../componets/timeType.vue'
export default {
  name: "overviewParkBusiness",
  data() {
    return {
      activeLI: 1,
      echartsDOM: null,
      info: {},
      startDate: '',
      endDate: '',
      tableData: [0],
      total: 0,
      type: 0
    };
  },
  computed: {
    getRegionalCollection() {
      return this.$store.state.regionalCollection;
    }
  },
  watch: {
    getRegionalCollection: {
      handler(newVal) {
        this.info = newVal;
        this.getData(newVal);
      }
    }
  },
  components: {
    timeType
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
    });
    window.addEventListener("resize", () => {
      this.echartsDOM.resize();
      this.initCharts();
    });
  },
  methods: {
    // 获取数据
    getData(data) {
      this.tableData = [];
      this.total = 0;
      this.$api.rightSideApi.queryParkEvaluationByScore({
        dateType: this.type != 15 ? 1 : 2,
        areaId: data.areaId,
        dataSource: data.dataSource,
        startDate: this.startDate || this.$TimeAll(0)[0],
        endDate: this.endDate || this.$TimeAll(0)[1]
      }).then(res => {
        let arr = res.value;
        if (res.value.length > 0) {
          this.tableData = arr.map(val => {
            this.total += val.parkCnt;
            return {
              name: val.scoreDesc,
              value: val.parkCnt
            }
          });
        } else {
          this.tableData = [0]
        }
        this.initCharts();
      })
    },
    getTimeType(date) {
      this.startDate = date[0];
      this.endDate = date[1];
      this.type = date[2];
      this.getData(this.info)
    },
    initCharts() {
      var that = this;
      var chartDom = document.getElementById("serviceEvalution");
      var myChart = this.$echarts.init(chartDom);
      this.echartsDOM = myChart;
      var option = {
        title: {
          text: `${that.total}次`,
          // 副标题
          subtext: `评价总数`,
          // 主副标题间距
          itemGap: 10,
          x: '18%',
          y: 'center',
          top: '35%',
          // 主标题样式
          textStyle: {
            fontSize: this.fontSize(1),
            color: '#ffffff'
          },
          // 副标题样式
          subtextStyle: {
            fontSize: this.fontSize(0.6),
            color: '#ffffff',
            align: 'center'
          },
          textAlign: 'center'
        },
        color: ["rgba(105, 223, 228, 1)", "rgba(62, 138, 228, 1)", "rgba(119, 134, 239, 1)", "rgba(99, 218, 153, 1)", "rgba(216, 229, 224, 1)"],
        tooltip: {
          trigger: "item",
          formatter(params) {
            let tip = `
              ${params.marker} ${params.name} ${params.value} 个 ${params.percent} %
            `
            return tip
          },
        },
        legend: {
          orient: "vertical",
          y: 'center',
          itemGap: 19,
          left: "40%",
          bottom: "15%",
          height: '60%',
          itemWidth: 3,
          itemHeight: 11,
          formatter: function (name) {
            // 该函数用于设置图例显示后的百分比
            var total = 0;
            var value;
            // debugger;
            // that.industryData.forEach((item)=>{
            //     total += Number(item.value);
            //     if (item.name == name) {
            //         value = item.value;
            //     }
            // })
            // var p = Math.round(((value / total) * 100)); //求出百分比
            return `${name}`; // 返回出图例所显示的内容是名称+百分比
          },
          textStyle: {
            color: "#D8E9FF",
            fontSize: 12,
          },
        },
        textStyle: {
          // 图例文字的样式
          color: "#ADADAD",
          fontSize: 12,
        },
        series: [
          {
            name: "客诉总数",
            type: "pie",
            // top: -20,
            // left: -220,
            // radius: ["57%", "63%"],
            radius: ["70%", "77%"],
            center: ["20%", "50%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
                // color: '#4c4a4a',
                formatter:
                  "{a|" +
                  200 +
                  "}" +
                  "{b|" +
                  "次" +
                  "}" +
                  "\n\r" +
                  "{c|评价总分}",

                rich: {
                  a: {
                    color: "#fff",
                    fontSize: 22,
                    lineHeight: 40,
                    textDecoration: "underline",
                  },
                  b: {
                    color: "#fff",
                    fontSize: 12,
                    foneWeight: "bold",
                  },
                  c: {
                    color: "#fff",
                    fontSize: 12,
                  },
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.tableData
          },
        ],
      };
      // 图例改变
      myChart.on("legendselectchanged", (params) => {
        this.setOption(myChart, params);
      });
      // 鼠标滑过
      // myChart.on("mouseover", (params) => {
      //   this.actionMouseOn(myChart, params);
      // });

      myChart.setOption(option);
    },
    fontSize(res) {
      let clientWidth = document.getElementById("leftThreeChart1").clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return res * fontSize;
    },
    actionMouseOn(myChart, params) {
      // currName = params.value;
      let op = myChart.getOption();
      let _label = {
        normal: {
          show: true,
          position: "center",
          formatter: [`{a|${params.value}}`, `{b|${params.name}}`].join("\n"),
          rich: {
            a: {
              color: "#fff",
              fontSize: 18,
              lineHeight: 30,
            },
            b: {
              color: "#fff",
              fontSize: 12,
              foneWeight: "bold",
            },
          },
        },
      };
      if (params.seriesIndex === 1) {
        _label.normal.formatter = ["zhiliang"].join("\n");
      }
      op.series[0].label = _label;
      myChart.setOption(op, true);
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartsContent-wrap {
  margin-top: 16px;
  padding: 0 16px;

  .leftThreeChart {
    padding-top: 16px;
    height: 17vh;
  }
}
</style>
