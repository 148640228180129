// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/guestUnitPrice.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../img/averageBerthRevenue.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".content[data-v-d76b4e4a] {\n  padding-top: 2.08333vw;\n}\n.content .img[data-v-d76b4e4a] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: space-evenly;\n      -ms-flex-pack: space-evenly;\n          justify-content: space-evenly;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.content .img .guestUnitPrice[data-v-d76b4e4a] {\n  width: 5vw;\n  height: 5vw;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n}\n.content .img .line[data-v-d76b4e4a] {\n  width: 1px;\n  height: 3.125vw;\n  background: #2e516d;\n}\n.content .img .averageBerthRevenue[data-v-d76b4e4a] {\n  width: 5vw;\n  height: 5vw;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n}\n.content .text[data-v-d76b4e4a] {\n  text-align: center;\n  font-size: 0.625vw;\n  font-weight: 400;\n  color: #dbf3ff;\n  line-height: 1.5625vw;\n  text-shadow: 0px 1px 0.46875vw #00b6ff;\n}\n.content .text .left[data-v-d76b4e4a] {\n  width: 50%;\n}\n.content .text .left p[data-v-d76b4e4a]:first-child {\n  text-indent: 0.625vw;\n}\n.content .text .left p[data-v-d76b4e4a]:last-child {\n  color: #b8eaff;\n  font-size: 1.09375vw;\n}\n.content .text .right[data-v-d76b4e4a] {\n  width: 50%;\n}\n.content .text .right p[data-v-d76b4e4a]:first-child {\n  text-indent: 0.625vw;\n}\n.content .text .right p[data-v-d76b4e4a]:last-child {\n  color: #b8eaff;\n  font-size: 1.09375vw;\n}\n", ""]);
// Exports
module.exports = exports;
