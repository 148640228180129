var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("timeType", {
        staticClass: "spacing",
        attrs: { title: "服务车次数" },
      }),
      _c("ytdTime", { on: { getTimeType: _vm.getTimeType } }),
      _c("div", {
        staticClass: "numberServiceVehicles",
        attrs: { id: "numberServiceVehicles" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }