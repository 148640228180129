var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chartsContent-wrap" },
    [
      _c("timeType", {
        attrs: {
          tip: "统计范围内用户对车场进行投诉反馈的不同类型占比",
          title: "客诉类型分析",
        },
        on: { getTimeType: _vm.getTimeType },
      }),
      _c("div", {
        staticClass: "leftThreeChart",
        attrs: { id: "leftThreeChart1Dp" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }