<template>
  <!-- 停车周转分析 -->
  <div class="chartsContent-wrap">
    <div class="title">
      停车周转分析

      <tips
        :tip="'入口流率：统计范围内路段泊位入口车辆数时变（0-24小时）情况<br/>出口流率：统计范围内路段泊位出口车辆数时变（0-24小时）情况'"
      />
    </div>
    <div class="turnoverAnlysisDp" id="turnoverAnlysisDp"></div>
  </div>
</template>

<script>
import tips from "../componets/tip.vue";
import { max, min } from "moment";
export default {
  name: "overviewParkBusiness",
  data() {
    var that = this;
    return {
      echartsDOM: null,
      xList: [],
      entryList: [],
      exitList: [],
    };
  },
  components: { tips },
  computed: {
    getRegionalCollection() {
      return this.$store.state.regionalCollection;
    },
  },
  watch: {
    getRegionalCollection: {
      handler(newVal) {
        // console.log(newVal, '----------------------')
        this.getData(newVal);
      },
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
    });

    /* 窗口变化时自适应 步骤一:监听窗口变化 */
    window.addEventListener("resize", () => {
      this.echartsDOM.resize();
    });
  },
  methods: {
    getData(data) {
      this.xList = [];
      this.entryList = [];
      this.exitList = [];
      this.$api.leftSideApi
        .parkTurnOverDay({
          areaId: data.areaId,
          dataSource: data.dataSource,
        })
        .then((res) => {
          let arr = res.value;
          if (Object.keys(arr).length > 0) {
            Object.keys(arr).forEach((val) => {
              this.xList.push(val);
              this.entryList.push(arr[val].parkIn);
              this.exitList.push(arr[val].parkOut);
            });
          }
          this.initCharts();
        });
    },
    changetimeData(timeData) {
      let newdata = [];
      timeData.forEach(function (str) {
        newdata.push(str.replace("2009/", ""));
      });
      return newdata;
    },
    initCharts() {
      var that = this;
      var chartDom = document.getElementById("turnoverAnlysisDp");
      var myChart = this.$echarts.init(chartDom);
      this.echartsDOM = myChart;
      var option = {
        // color: ["rgba(165, 184, 255, 1)", "rgba(255, 255, 255, 1)"],
        color: ["rgba(131, 168, 255, 1)", "rgba(255, 214, 92, 1)"],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["入场流率", "出场流率"],
          //  data: ['入口流率', '出口流率'],
          left: "right",
          itemWidth: 15, // 图例的高度
          itemHeight: 5, // 图例的高度
          textStyle: {
            color: "#D8E9FF",
            fontSize: "12",
          },
        },
        grid: [
          {
            left: "8%",
            right: 0,
            bottom: "14%",
            top: 50,
            // height: "55%",
          },
        ],
        xAxis: {
          type: "category",
          data: this.xList || [],
          axisPointer: {
            type: "shadow",
          },
          axisLine: {
            onZero: true,
            lineStyle: {
              type: "solid",
              color: "#3C8BC5", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisLabel: {
            color: "#94B4CA", // 刻度样式
          },
          boundaryGap: true,
        },

        yAxis: {
          type: "value",
          name: "车次（次）",
          offset: 0,
          nameTextStyle: {
            color: "#fff",
            fontSize: 12,
            padding: [0, -14, 0, 0],
          },
          max: function (value) {
            return Math.ceil(value.max);
          },
          min: 0,
          splitNumber: 6,
          splitLine: {
            show: true,
            lineStyle: {
              type: "dotted",
              color: "#3C8BC5", // 左边线的颜色
              width: "1", // 坐标线的宽度
            },
          },
          axisTick: {
            show: false, // y轴坐标点是否消失
          },

          axisLine: {
            // y轴
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#94B4CA",
            },
            formatter: "{value}",
          },
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            height: 4,
            left: "1%",
            right: "1%",
            bottom: "0",
            start: 0,
            end: 100,
            filterMode: "empty",
            fillerColor: "rgba(78, 108, 167, 1)",
            backgroundColor: "rgba(41, 62, 103, 1)",
            borderColor: "none",
            textStyle: {
              color: "#d4ffff",
              fontSize: 11,
              border: "none",
            },
          },
          {
            type: "inside",
          },
        ],
        series: [
          {
            name: "入场流率",
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(164, 196, 255, 0.5)", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(131, 168, 255, 0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#A6B8FF",
                  width: 1,
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + "";
              },
            },
            data: this.entryList || [],
          },
          {
            name: "出场流率",
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(164, 196, 255, 0.5)", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(131, 168, 255, 0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "rgba(255, 214, 92, 1)",
                  width: 1,
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + "";
              },
            },
            data: this.exitList || [],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartsContent-wrap {
  margin-top: 16px;
  padding: 0 16px;

  .title {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #E7F8FF;
    padding-left: 20px;
    background: url('../../images/tip-bottom.png') no-repeat center bottom;
    background-size: 100%;;
  }

  .turnoverAnlysisDp {
    // height: 26vh;
    height: 264px
  }
}
</style>
