<template>
  <!-- 泊位饱和度趋势及预测 -->
  <div class="chartsContent-wrap">
    <div class="title">
      泊位饱和度趋势及预测
      <tips
        :tip="'泊位占用率：统计范围内已用泊位与总泊位数量比值。表征区域或单个停车场泊位供给是否充足，指标越大，表明当前可供使用泊位越少，支撑区域停车发展、停车场资源服务（调度、引流等）。'"
      />
    </div>
    <div class="leftThreeChartDp" id="leftThreeChartDp"></div>
  </div>
</template>

<script>
import tips from "../componets/tip.vue";
import * as echarts from "echarts";
export default {
  name: "overviewParkBusiness",
  data() {
    return {
      echartsDOM: null,
      xList: [],
      freeBerth: [],
      useRate: [],
      usedBerth: [],
      linePredict: [],
    };
  },
  components: { tips },
  computed: {
    getRegionalCollection() {
      return this.$store.state.regionalCollection;
    },
  },
  watch: {
    getRegionalCollection: {
      handler(newVal) {
        // console.log(newVal, '----------------------')
        this.getData(newVal);
      },
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initCharts();
    });
    window.addEventListener("resize", () => {
      this.echartsDOM.resize();
    });
  },
  methods: {
    /**
     * xList X轴数据
     * freeBerth 空闲泊位
     * useRate 泊位占用率
     * usedBerth 占用泊位
     * linePredict 预测数据
     */
    getData(data) {
      this.xList = [];
      this.freeBerth = [];
      this.useRate = [];
      this.usedBerth = [];
      this.linePredict = [];
      this.$api.leftSideApi
        .parkBerthOccupyDay({
          areaId: data.areaId,
          dataSource: data.dataSource,
        })
        .then((res) => {
          let arr = res.value;
          if (Object.keys(arr).length > 0) {
            Object.keys(arr).forEach((val) => {
              this.xList.push(val);
              // DataType == predict 为预测数据需要修改样式虚线化
              this.linePredict.push(arr[val].useRate);
              if (arr[val].DataType != "predict") {
                this.freeBerth.push(arr[val].freeBerth);
                this.usedBerth.push(arr[val].usedBerth);
                this.useRate.push(arr[val].useRate);
                // this.linePredict.push(null)
              } else {
                this.usedBerth.push({
                  value: arr[val].usedBerth,
                  itemStyle: {
                    normal: {
                      color: "none",
                      opcity: 1,
                      barBorderColor: "rgba(34, 253, 255, 1)",
                      // barBorderWidth: 1,
                      // barBorderRadius: 0,
                      borderType: "dotted",
                    },
                  },
                });
                this.freeBerth.push({
                  value: arr[val].freeBerth,
                  itemStyle: {
                    normal: {
                      opcity: 1,
                      color: "none",
                      barBorderColor: "rgba(63, 102, 247, 1)",
                      // barBorderWidth: 1,
                      // barBorderRadius: 0,
                      borderType: "dotted",
                    },
                  },
                });
              }
            });
          }
          this.initCharts();
        });
    },
    initCharts() {
      var that = this;
      var chartDom = document.getElementById("leftThreeChartDp");
      var myChart = this.$echarts.init(chartDom);
      this.echartsDOM = myChart;
      var option = {
        color: ["rgba(165, 184, 255, 1)", "rgba(255, 255, 255, 1)"],
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let tooltip = "";
            params.forEach((val) => {
              let marker0 = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, #22FDFF 0%, rgba(8, 94, 97, 0.5) 100%);"></span>`;
              let marker1 = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, #496FFC 0%, rgba(10, 36, 131, 0.5) 100%);"></span>`;
              if (val.seriesName == "占用泊位") {
                tooltip += marker0 + `占用泊位: ${val.value}` + "</br>";
              }
              if (val.seriesName == "空闲泊位") {
                tooltip += marker1 + `空闲泊位: ${val.value}` + "</br>";
              }
              if (val.seriesName == "无泊位占用率") {
                tooltip +=
                  val.marker +
                  `泊位占用率: ${val.value == "NaN" || val.value == 0 ? 0 : val.value + "%"}`;
              }
            });
            return tooltip;
          },
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: ["占用泊位", "空闲泊位"],
          left: "center",
          textStyle: {
            color: "#D8E9FF",
            fontSize: "12",
          },
          icon: "rect",
          itemHeight: 6, // 修改icon图形大小
          itemWidth: 6, // 修改icon图形大小
        },
        grid: [
          {
            left: "12%",
            right: "12%",
            bottom: "20%",
            top: 50,
            // height: "55%",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: this.xList,

            axisPointer: {
              type: "shadow",
            },

            axisLine: {
              onZero: true,
              lineStyle: {
                type: "solid",
                color: "#3C8BC5", // 左边线的颜色
                width: "1", // 坐标线的宽度
              },
            },
            axisLabel: {
              color: "#94B4CA", // 刻度样式
            },
            boundaryGap: true,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "泊位个数（个）",
            splitNumber: 6,
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              padding: [0, -15, 0, 2],
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dotted",
                color: "#3C8BC5", // 左边线的颜色
                width: "1", // 坐标线的宽度
              },
            }, // 网格线
            axisLine: {
              show: false, // y轴线消失
            },
            axisTick: {
              show: false, // y轴坐标点是否消失
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#94B4CA",
              },
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "泊位占用率",
            max: 100,
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              padding: [0, 14, 0, 0],
            },
            splitNumber: 6,
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: "#3C8BC5", // 左边线的颜色
                width: "1", // 坐标线的宽度
              },
            },
            axisTick: {
              show: false, // y轴坐标点是否消失
            },

            axisLine: {
              // y轴
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#94B4CA",
              },
              formatter: "{value}%",
            },
          },
        ],
        dataZoom: [
          {
            type: "slider",
            show: true,
            height: 4,
            left: "1%",
            right: "1%",
            bottom: "8%",
            start: 0,
            end: 100,
            filterMode: "empty",
            fillerColor: "rgba(78, 108, 167, 1)",
            backgroundColor: "rgba(41, 62, 103, 1)",
            borderColor: "none",
            textStyle: {
              color: "#d4ffff",
              fontSize: 11,
              border: "none",
            },
          },
          {
            type: "inside",
          },
        ],
        series: [
          {
            name: "占用泊位",
            type: "bar",
            barWidth: 4,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // { offset: 0, color: "#22FDFF" },
                // { offset: 1, color: "#085E61" },
                { offset: 0, color: "rgba(34, 253, 255, 1)" },
                { offset: 1, color: "rgba(8, 94, 97, 0.5)" },
              ]),
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ml";
              },
            },
            data: this.usedBerth,
            // data: [
            //   2.0,
            //   4.9,
            //   7.0,
            //   23.2,
            //   25.6,
            //   76.7,
            //   {
            //     value: 63.3,
            //     itemStyle: {
            //       normal: {
            //         color: 'none',
            //         opcity: 1,
            //         barBorderColor: "#22FDFF",
            //         barBorderWidth: 1,
            //         barBorderRadius: 0,
            //         borderType: "dotted",
            //       },
            //     },
            //   },
            // ],
          },
          {
            name: "空闲泊位",
            type: "bar",
            barWidth: 4,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // { offset: 0, color: "#496FFC" },
                // { offset: 1, color: "#0A2483" },
                { offset: 0, color: "rgba(73, 111, 252, 1)" },
                { offset: 1, color: "rgba(10, 36, 131, 0.5)" },
              ]),
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " ml";
              },
            },
            data: this.freeBerth,
            // data: [
            //   2.6,
            //   5.9,
            //   9.0,
            //   26.4,
            //   28.7,
            //   70.7,
            //   {
            //     value: 163.3,
            //     itemStyle: {
            //       normal: {
            //         opcity: 1,
            //         color: 'none',
            //         barBorderColor: "rgba(63, 102, 247, 1)",
            //         barBorderWidth: 1,
            //         barBorderRadius: 0,
            //         borderType: "dotted",
            //       },
            //     },
            //   },
            // ],
          },
          {
            name: "泊位占用率",
            type: "line",
            smooth: true,
            //   areaStyle: {
            //   normal: {
            //     color: {
            //       x: 0,
            //       y: 0,
            //       x2: 0,
            //       y2: 1,
            //       colorStops: [
            //         {
            //           offset: 0,
            //           color: "rgba(164, 196, 255, 0.5)", // 0% 处的颜色
            //         },
            //         {
            //           offset: 0.7,
            //           color: "rgba(131, 168, 255, 0)", // 100% 处的颜色
            //         },
            //       ],
            //       globalCoord: false, // 缺省为 false
            //     },
            //   },
            // },
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#A6B8FF",
                  width: 2,
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + " °C";
              },
            },
            data: this.useRate,
            // data: [2.0, 2.2, 3.3, 4.5, 6.3, 12.0],
          },
          {
            name: "无泊位占用率",
            type: "line",
            smooth: true,
            //   areaStyle: {
            //   normal: {
            //     color: {
            //       x: 0,
            //       y: 0,
            //       x2: 0,
            //       y2: 1,
            //       colorStops: [
            //         {
            //           offset: 0,
            //           color: "rgba(164, 196, 255, 0.5)", // 0% 处的颜色
            //         },
            //         {
            //           offset: 0.7,
            //           color: "rgba(131, 168, 255, 0)", // 100% 处的颜色
            //         },
            //       ],
            //       globalCoord: false, // 缺省为 false
            //     },
            //   },
            // },
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                lineStyle: {
                  type: "dotted",
                  color: "#A6B8FF",
                  width: 1,
                },
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return "";
              },
            },
            data: this.linePredict,
            // data: [null, null, null, null, null, 12.0, 6.2],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartsContent-wrap {
  margin-top: 16px;
  padding: 0 16px;

  .title {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #E7F8FF;
    padding-left: 20px;
    background: url('../../images/tip-bottom.png') no-repeat center bottom;
    background-size: 100%;
  }

  .leftThreeChartDp {
    padding-top: 16px;
    // height: 26vh;
    height: 244px
  }
}
</style>
