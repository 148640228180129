<template>
  <div class="inMapMain">
    <div
      class="header"
      :style="[{ left: isDp ? '295px' : '' }, { transform: isDp ? 'scale(1.6)' : 'scale(1)' }]"
    >
      <span
        class="titleIcon"
        :style="[
          { position: isDp ? 'absolute' : '' },
          { paddingTop: isDp ? '40px' : '6px' },
          { left: isDp ? '49%' : '' },
        ]"
        >AIPARK 智慧泊车管理平台</span
      >
      <img
        src="./images/titleov.png"
        alt=""
        class=""
        style="width: 100%"
        :style="{ marginTop: isDp ? '2px' : '-42px' }"
      />
      <img
        src="./images/choiceov.png"
        class=""
        style="width: 962px; margin-top: -30px; transform: translateY(6px)"
        alt=""
      />
      <div class="choiceovC">
        <div v-for="(item, index) in choiceovCS" :key="index" class="choiceovCItem">
          <span
            :class="getMax(goevaluateindexMap, index) == 1 ? 'ccItemClor ccItem2' : ''"
            class="ccItem"
            @click="secletPark(index)"
            >{{ item }}</span
          >
        </div>
      </div>
    </div>

    <!-- 预测分析概览页 -->
    <!-- 左边组件 -->
    <leftChartsContent v-if="$route.path == '/home' && tabContent"></leftChartsContent>
    <leftChartsContentOld v-show="$route.path == '/home' && !tabContent"></leftChartsContentOld>
    <!-- 右边组件 -->
    <rightChartsContent v-if="$route.path == '/home' && tabContent"></rightChartsContent>
    <rightChartsContentOld v-show="$route.path == '/home' && !tabContent"></rightChartsContentOld>

    <!-- 适配大屏 -->
    <!-- 左侧组件 -->
    <leftChartsContentDP
      v-if="$route.path == '/java_home_daping' && tabContent"
    ></leftChartsContentDP>
    <leftChartsContentOldDP
      v-show="$route.path == '/java_home_daping' && !tabContent"
    ></leftChartsContentOldDP>
    <!-- 右边组件 -->
    <rightChartsContentDP
      v-if="$route.path == '/java_home_daping' && tabContent"
    ></rightChartsContentDP>
    <rightChartsContentOldDP
      v-show="$route.path == '/java_home_daping' && !tabContent"
    ></rightChartsContentOldDP>

    <!-- 地图 -->
    <div class="homeContent">
      <div id="container"></div>
    </div>
    <!-- 搜索 -->
    <div
      class="parkSearch"
      v-show="!tabContent"
      :style="[
        { transform: isDp ? 'scale(1.8)' : 'scale(1)' },
        { left: isDp ? 'calc(16% + 5px)' : 'calc(25% + 5px)' },
        { top: isDp ? '170px' : '82px' },
      ]"
    >
      <el-dropdown @command="handleCommand" placement="bottom-start" class="dropdownWrapper">
        <div class="el-dropdown-link parkSearch-menuBar">
          <span class="el-dropdown-link menuBarFs">
            {{ downName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        </div>
        <el-dropdown-menu slot="dropdown" class="dropdownStyle">
          <el-dropdown-item command="0">位置</el-dropdown-item>
          <el-dropdown-item command="1">停车场</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-autocomplete
        popper-class="my-autocomplete"
        v-model="parkName"
        style="margin-top: 6px; margin-left: 10px"
        :fetch-suggestions="querySearchAsync"
        :placeholder="inputPlaceholder"
        :trigger-on-focus="false"
        v-show="!addressshow"
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <div class="name" v-if="isParkingLot">{{ item.parkName }}</div>
        </template>
      </el-autocomplete>
      <input
        id="pickerInput"
        v-show="addressshow"
        v-model="pickerdata"
        @focus="getfocus"
        autocomplete="off"
        :placeholder="inputPlaceholder"
        style="
          position: absolute;
          z-index: 3;
          left: 68px;
          top: 3px;
          width: 130px;
          height: 24px;
          border: none;
          padding: 0px;
        "
      />
    </div>
    <!--车场详情-->
    <div class="parkDetailWraper" v-show="parkDetailWraper" ref="parkDetailWraper">
      <parkDetail :parkDetailValue="parkDetailValue" :videoList="videoList"></parkDetail>
    </div>
    <!-- 全部车场、路内车场、路外车场 -->
    <div
      class="parkCS"
      :style="[
        { left: isDp ? 'calc(49% - 115px)' : 'calc(48% - 122px)' },
        { transform: isDp ? 'scale(1.5)' : 'scale(1)' },
        { bottom: isDp ? '80px' : '62px' },
      ]"
    >
      <div
        class=""
        :class="parkCSleParms == index ? 'parkCSItemColor parkCSItem' : 'parkCSItemHover'"
        v-for="(item, index) in parkS"
        :key="index"
        @click="parkCSle(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="parkAll" :style="{ transform: isDp ? 'scale(1.3)' : 'scale(1)' }">
      <img
        :src="bootomLeftImg"
        alt=""
        class="parkAllImg"
        @click="parkSle(2)"
        @mouseover="onOut(1)"
        @mouseout="inOut(1)"
        style="margin-right: 56px"
      />
      <div class="parkBox2" ref="parkBox2" :style="{ transform: isDp ? 'scale(1.5)' : 'scale(1)' }">
        <span
          class="parkAllItem"
          :class="parkAllItemParms.areaCode == item.areaCode ? 'parkAllItemColor' : ''"
          v-for="(item, index) in currentArea"
          :key="index"
          @click="parkSleItem(item)"
        >
          {{ item.areaName }}
        </span>
      </div>
      <img
        :src="bootomRightImg"
        alt=""
        class="parkAllImg"
        @click="parkSle(1)"
        @mouseover="onOut(2)"
        @mouseout="inOut(2)"
        style="margin-left: 56px"
      />
    </div>
    <div class="bottomS"></div>
    <div
      class="fullFigure"
      :style="[
        { transform: isDp ? 'scale(2)' : 'scale(1)' },
        { top: isDp ? '15%' : '8%' },
        { right: isDp ? '15%' : 'calc(26% - 10px' },
      ]"
    >
      <h1>剩余泊位</h1>
      <div><span class="fullFigure-one"></span><span>50%以上</span></div>
      <div><span class="fullFigure-two"></span><span>20%~50%</span></div>
      <div><span class="fullFigure-three"></span><span>20%以下</span></div>
    </div>
    <detailedBox ref="detailedBox"></detailedBox>
    <personnel ref="personnel"></personnel>
    <video-monitor
      ref="videoMonitor"
      :passageway-list="passagewayList"
      :isShowChange="isShowChange"
      @change="videoChange"
      v-if="isShowVideoDialog"
      :isShowPassageway="false"
      :title="title"
      :isPlayBack="isPlayBack"
      @close="isShowVideoDialog = false"
    ></video-monitor>
  </div>
</template>
<script>
import detailedBox from "@/components/detailedBox";
import personnel from "@/components/detailedBox/personnel";
import leftChartsContent from "./chartsContent/newLeftCharts";
import rightChartsContent from "./chartsContent/newRightCharts";
import leftChartsContentOld from "./chartsContent/leftCharts";
import rightChartsContentOld from "./chartsContent/rightCharts";
import leftChartsContentDP from "./DP/newLeftCharts";
import rightChartsContentDP from "./DP/newRightCharts";
import leftChartsContentOldDP from "./DP/leftCharts";
import rightChartsContentOldDP from "./DP/rightCharts";
import parkDetail from "./componets/summarizePark";
import VideoMonitor from "@/components/video-monitor/newindex";
export default {
  name: "newHome",
  data() {
    return {
      // 头部筛选
      choiceovCS: ["热力图", "停车场", "泊位", "收费员", "监控设备", "事件预警"],
      choiceovSY: "4",
      goevaluateindexMap: [0, 1, 2, 3, 4, 5],
      stop: "0",
      // 大额欠费
      LargePosition: [],
      largeMarkerS: [],
      largetimer: "",
      // 底部筛选
      parkAllItemParms: "0",
      parkCSleParms: "0",
      translateXData: "translateX(0px)",
      parkS: ["全部车场", "路内车场", "路外车场"],
      parkSAll: [],
      bootomLeftImg: require("./images/parkleft.png"),
      bootomRightImg: require("./images/parkright.png"),
      adcode: "110000",
      selAreaID: "",
      offsetWidth: 0,
      currentArea: [],
      currentAreaIndex: 0,
      currentAreaWidth: 0,
      // 搜索
      downName: "停车场",
      parkName: "",
      inputPlaceholder: "请输入停车场",
      isParkingLot: true,
      parkNumber: false,
      autoOptions: {
        input: "pickerInput",
      },
      addressshow: false,
      pickerdata: "",
      // 车场详情
      parkDetailValue: {},
      parkDetailWraper: false,
      videoList: [],
      // 地图
      map: "",
      zoomInit: 12,
      iconMasterN11: require("./images/iconMasterN11.svg"),
      iconMasterN22: require("./images/iconMasterN22.svg"),
      iconMasterN33: require("./images/iconMasterN33.svg"),
      iconMasterY11: require("./images/iconMasterY11.svg"),
      iconMasterY22: require("./images/iconMasterY22.svg"),
      iconMasterY33: require("./images/iconMasterY33.svg"),
      largeImg: require("@/assets/img/car-pic.png"),
      lineImg: require("./images/line.png"),
      berth1: require("./images/berth1.png"),
      berth2: require("./images/berth2.png"),
      collector1: require("./images/collector1.svg"),
      collector2: require("./images/collector2.svg"),
      highlevel: require("./images/highlevel.svg"),
      districtExplorer: "",
      mars: [],
      basePosition: "",
      parksMarkers: [],
      berthMarkers: [],
      collectorMarkers: [],
      equipmentMarkers: [],
      onclickTag: 1,
      onclickTagParkid: "",
      // 热力图
      parkNameListAll: [],
      heatmap: "",
      // 定时任务
      timmer: "",
      tagMap: "",
      ItemMap: 999,
      markers: [],
      markersChildren: [],
      targetIcon: "",
      targetIconIMG: "",
      // 切换
      tabContent: false,
      // 视频监控
      dialogVisible: false,
      monitorDeviceNo: "",
      monitorDeviceName: "",
      title: "",
      passagewayList: [],
      isShowChange: true,
      isShowVideoDialog: false,
      passagewayPageSize: 5,
      passagewayPage: 1,
      passagewayTotal: 0,
      selectData: {},
      isPlayBack: true, // 是否支持回放
      scollHeight: null, // 浏览器视口高度
      leftContent: null,
      rightContent: null,
    };
  },
  components: {
    leftChartsContent,
    rightChartsContent,
    leftChartsContentOld,
    rightChartsContentOld,
    leftChartsContentDP,
    rightChartsContentDP,
    leftChartsContentOldDP,
    rightChartsContentOldDP,
    parkDetail,
    detailedBox,
    personnel,
    VideoMonitor,
  },
  computed: {
    coordinate() {
      return this.$store.state.coordinate;
    },
    isDp() {
      return this.scollHeight == 1536;
    },
    path() {
      return this.$route.path == "/java_home_daping";
    },
  },
  watch: {
    coordinate: {
      handler(newVal) {
        console.log("onclickTag点击1搜索0=>", newVal.coordinate.onclickTag);
        this.setCenterCoordinate(newVal.coordinate);
        if (newVal.coordinate.onclickTag == 0) {
          // this.screenParkID(newVal.coordinate.parkId)
          this.onclickTagParkid = newVal.coordinate.parkId;
          this.onclickTag = 0;
        } else {
          this.onclickTagParkid = "";
          this.onclickTag = 1;
        }
      },
    },
    scollHeight: {
      handler(val) {
        if (this.path) {
          this.setTransformStyles();
        }
      },
    },
    tabContent: {
      handler(val) {
        if (this.path) {
          this.$nextTick(() => {
            this.leftContent = document.querySelector(val ? ".bgLeftDP" : ".leftChartsContentDP");
            this.rightContent = document.querySelector(
              val ? ".bgRightDP" : ".rightChartsContentDP"
            );
            this.setTransformStyles();
          });
        }
      },
    },
  },
  methods: {
    setTransformStyles() {
      const scale = this.scollHeight > 1080 ? this.scollHeight / 1080 : 1;
      const translateY = this.scollHeight > 1080 ? -50 : -49;
      this.leftContent.style.transform = `scale(${scale}) translateY(${translateY}%)`;
      this.leftContent.style.transformOrigin = "left top";
      this.rightContent.style.transform = `scale(${scale}) translateY(${translateY}%)`;
      this.rightContent.style.transformOrigin = "right top";
    },
    async showVideo(item) {
      this.passagewayPageSize = 5;
      this.passagewayPage = 1;
      this.passagewayTotal = 0;
      await this.getPassagewayList(item.monitorEquipmentId);
      if (this.passagewayList.length == 0) {
        this.$alert("此监控下没有监控设备！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.selectData = item;
      // this.title = item.monitorEquipmentName;
      this.dialogVisible = false;
      this.isShowVideoDialog = true;
      this.$nextTick(() => {
        this.$refs.videoMonitor.open();
      });
    },
    async videoChange() {
      if (this.passagewayPage > this.passagewayFlag) {
        this.passagewayPage = 1;
      }
      await this.getPassagewayList(this.selectData.monitorEquipmentId);
      this.$nextTick(() => {
        this.$refs.videoMonitor.clearData();
      });
    },
    getPassagewayList(monitorEquipmentId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/monitorEquipmentChannel/list", {
            data: {
              page: this.passagewayPage,
              pageSize: this.passagewayPageSize,
              monitorEquipmentId,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.passagewayList = res.value.list;
              this.passagewayTotal = res.value.total;
              this.passagewayFlagA = this.passagewayTotal % this.passagewayPageSize;
              this.passagewayFlagB = parseInt(this.passagewayTotal / this.passagewayPageSize);
              this.title = res.value.list ? res.value.list[0].monitorEquipmentChannelName : "";
              if (this.passagewayFlagA > 0) {
                this.passagewayFlag = this.passagewayFlagB + 1;
              } else {
                this.passagewayFlag = this.passagewayFlagB;
              }
              // console.log(this.passagewayFlag);
              this.passagewayPage++;
              // this.passagewayFlag = this.passagewayTotal/this.passagewayPageSize
            } else {
              this.passagewayPage = 1;
              this.passagewayList = [];
              this.passagewayTotal = 0;
            }
            if (this.passagewayTotal > 5) {
              this.isShowChange = true;
            } else {
              this.isShowChange = false;
            }
            resolve();
          });
      });
    },
    // 视频监控列表
    getDataVideoList(parkId) {
      this.$axios
        .get("/acb/2.0/monitorEquipment/listMonitorEquipment", {
          data: {
            page: 1,
            pageSize: 15,
            parkId: parkId,
            monitorEquipmentName: "",
          },
        })
        .then((res) => {
          if (res.value.list.length > 0) {
            this.videoList = res.value.list;
          } else {
            this.videoList = [];
          }
        });
    },
    // 头部筛选
    secletPark(secletParms) {
      this.tagMap = secletParms;
      let arrayM = this.goevaluateindexMap; // 存选中的数组
      let falgMap = "";
      this.ItemMap = 999; // ==999 没选 否则选中了
      arrayM.filter((value, index) => {
        if (value == this.tagMap) {
          this.ItemMap = value;
          falgMap = index;
        }
      });
      if (this.ItemMap === 999) {
        arrayM.push(this.tagMap);
        // 热力图显示
        if (this.tagMap == 0) {
          this.getHeatMap();
          this.heatmap.show();
        }
        if (this.tagMap == 1) {
          // this.map.add(this.mars);
          this.map.add(this.parksMarkers);
        }
        // 泊位
        if (this.tagMap == 2) {
          // this.map.add(this.mars);
          this.map.add(this.berthMarkers);
        }
        // 收费员
        if (this.tagMap == 3) {
          // this.map.add(this.mars);
          this.map.add(this.collectorMarkers);
        }
        // 监控设备
        if (this.tagMap == 4) {
          // this.map.add(this.mars);
          this.map.add(this.equipmentMarkers);
        }
        if (this.tagMap == 5) {
          this.getLarge(1);
        }
      } else {
        arrayM.splice(falgMap, 1);
        if (this.tagMap == 0) {
          this.getHeatMap();
          this.heatmap.hide();
        }
        if (this.tagMap == 1) {
          // this.mars = this.map.getAllOverlays("marker");
          // this.map.remove(this.map.getAllOverlays("marker"));
          this.map.remove(this.parksMarkers);
        }
        // 泊位
        if (this.tagMap == 2) {
          this.map.remove(this.berthMarkers);
        }
        // 收费员
        if (this.tagMap == 3) {
          this.map.remove(this.collectorMarkers);
        }
        // 监控设备
        if (this.tagMap == 4) {
          this.map.remove(this.equipmentMarkers);
        }
        if (this.tagMap == 5) {
          this.getLarge(0);
        }
      }
      this.goevaluateindexMap = arrayM;
      // console.log("goevaluateindexMap", this.goevaluateindexMap);
      // console.log("this.ItemMap-->", this.ItemMap);
    },
    getHeaderSeclet(tag) {
      let a = this.goevaluateindexMap.filter((value) => value == tag);
      return a[0];
    },
    getMax(array, id) {
      var falg = 0;
      for (let i = 0; i < array.length; i++) {
        if (array[i] === id) {
          falg = 1;
        }
      }
      return falg;
    },
    // 大额欠费
    getLarge(stop) {
      let t = this;
      if (stop) {
        this.timmer = setInterval(() => {
          // console.log("测试");
          t.getLargeInfarce();
        }, 60000);
      } else {
        clearInterval(this.timmer);
        this.timmer = null;
      }
    },
    getLargeMarkers() {
      if (this.LargePosition.length == 0) {
        // 没有大额欠费无需绘制坐标
        return;
      }
      for (let i = 0; i < this.LargePosition.length; i++) {
        let showIcon = this.getParkIcon(this.LargePosition, i);
        const marker = new AMap.Marker({
          map: this.map,
          position: [
            this.LargePosition[i].longitude / 1000000,
            this.LargePosition[i].latitude / 1000000,
          ],
          // icon: showIcon,
          content: this.createInfoWindow(showIcon),
          extData: {
            LargePosition: this.LargePosition[i],
          },
        });
        this.largeMarkerS.push(marker);
      }
      if (this.LargePosition) {
        this.largetimer = setTimeout(() => {
          this.map.remove(this.largeMarkerS);
          this.largeMarkerS = [];
          clearTimeout(this.largetimer);
        }, 3000);
      }
    },
    // 构建点坐标
    createInfoWindow(showIcon) {
      //    <img class= "imgpark" src="${this.largeImg}">
      var info = document.createElement("div");
      info.innerHTML = `
        <div class="large">
            <img class="imgpark" src="${showIcon}">
            <span class="largeimgpark">大额欠费<span>
            <img class="line" src="${this.lineImg}">
        </div>
        `;
      return info;
    },
    // 大额欠费接口
    getLargeInfarce() {
      let t = this;
      let maxTimeStamp = new Date().getTime();
      console.log("maxTimeStamp", maxTimeStamp);
      this.$axios
        .get("/acb/2.0/dataAnalysisMap/getBigDebtAlarmList", {
          data: {
            maxTimeStamp: maxTimeStamp,
            queryCnt: "15",
            areaId: this.selAreaID ? this.selAreaID : this.parkAllItemParms.areaId,
          },
        })
        .then((res) => {
          this.LargePosition = res.value;
          t.getLargeMarkers();
        });
    },
    // 底部筛选
    parkCSle(indexParkCSleParms) {
      this.parkCSleParms = indexParkCSleParms;
      this.closeInfo();
      console.log("parkCSleParms", this.parkCSleParms);
      this.$store.commit("SET_regionalCollection", {
        areaId: this.parkAllItemParms.areaId,
        dataSource: this.parkCSleParms == 0 ? "" : this.parkCSleParms,
      });
      this.getHostParkNew(this.adcode, 1);
      if (this.tabContent == true) {
        this.map.setZoom(9);
      }
      this.tabContent = false;
    },
    parkSle(value) {
      // // 可视宽
      // let widthContent = parseInt(this.$refs.barparent.clientWidth);
      // // 总宽
      // let itemContent =  '0'
      // this.$refs.barparent.childNodes.forEach(item => {
      //   itemContent = parseInt(item.clientWidth) + parseInt(itemContent);
      // });
      // // 左键
      // if (this.offsetWidth >= widthContent && value == 2) {
      //    this.offsetWidth = (this.offsetWidth - widthContent) >  widthContent ? (this.offsetWidth - widthContent) : 0
      //    this.translateXData = `translateX(-${this.offsetWidth}px)`;
      // }
      // // 右键
      // if (itemContent - this.offsetWidth > widthContent && value == 1) {
      //    this.offsetWidth = this.offsetWidth + widthContent
      //    this.translateXData = `translateX(-${this.offsetWidth}px)`;
      // }
      this.getAreaCount(value);
    },
    // 获取当前可视宽度可以放多少个区
    getAreaCount(value) {
      // let widthContent = parseInt(this.$refs.barparent.clientWidth);
      let widthContent = parseInt(this.$refs.parkBox2.clientWidth);
      console.log("可视区域", widthContent);
      // 右键
      if (value == 1) {
        let currentAreaWidth = 0;
        let currentAreaItem = [];
        let i = this.currentAreaIndex;
        for (i; i < this.parkSAll.length; i++) {
          let item = this.parkSAll[i];
          let itemLength = item.areaName.length * 14 + 16;
          currentAreaWidth = currentAreaWidth + itemLength;
          if (widthContent - currentAreaWidth > 0) {
            currentAreaItem.push(item);
          } else {
            this.currentArea = currentAreaItem;
            this.currentAreaIndex = i;
            return;
          }
        }
        this.currentArea = currentAreaItem;
      }
      // 左键
      if (value == 2 && this.currentArea[0].areaCode != this.parkSAll[0].areaCode) {
        let currentAreaWidthJ = 0;
        let currentAreaItemJ = [];
        let j = this.currentAreaIndex;
        for (j; j > 0; j--) {
          let itemJ = this.parkSAll[j - 1];
          let itemLengthJ = itemJ.areaName.length * 14 + 16;
          currentAreaWidthJ = currentAreaWidthJ + itemLengthJ;
          if (widthContent - currentAreaWidthJ > 0) {
            currentAreaItemJ.unshift(itemJ);
          } else {
            this.currentAreaIndex = j;
            this.currentArea = currentAreaItemJ;
            return;
          }
        }
        this.currentArea = currentAreaItemJ;
        this.currentAreaIndex = j == 0 ? this.currentAreaIndex : j;
      }
    },
    onOut(pam) {
      if (pam == 2) {
        this.bootomRightImg = require("./images/parkright_hover.png");
      }
      if (pam == 1) {
        this.bootomLeftImg = require("./images/parkleft_hover.png");
      }
    },
    inOut(pam) {
      if (pam == 2) {
        this.bootomRightImg = require("./images/parkright.png");
      }
      if (pam == 1) {
        this.bootomLeftImg = require("./images/parkleft.png");
      }
    },
    parkSleItem(indexParkAllItemParms) {
      // 左右全局参数
      this.tabContent = false;
      this.closeInfo();
      // this.clearIconListS();
      this.markersChildren = [];
      this.parkAllItemParms = indexParkAllItemParms;
      this.selAreaID = indexParkAllItemParms.areaId;
      this.adcode = indexParkAllItemParms.areaCode;
      this.$store.commit("SET_regionalCollection", {
        areaId: indexParkAllItemParms.areaId || "",
        dataSource: this.parkCSleParms == 0 ? "" : this.parkCSleParms,
      });
      this.upDataRegion(this);
      this.getHostParkNew(this.adcode, 1);
      this.parkDetailWraper = false;
    },
    // 地图
    initMap() {
      this.map = new AMap.Map("container", {
        mapStyle: "amap://styles/a2830c4204ddc7ea7c685a99076c6bc3",
        zoom: this.zoomInit,
        pitch: 65,
        rotation: 0,
        viewMode: "3D",
        center: [116.397428, 39.90923],
      });
      this.districtClusterInitPae1(this);
      this.select(this);
      this.initHeatMapDom();
      // this.getInitMapBar();
      // 由于移动选点功能滑动后经常出现没有车场的情况、产品决定暂时去掉这个功能
      let t = this;
      // this.map.on('dragend',  function (e) {
      //   console.log("-->", t.map.getCenter());
      //   t.upArea1(t.map.getCenter(), t);
      // });
      this.map.on("zoomend", function (e) {
        console.log("getZoom", t.map.getZoom());
        //  产品要求缩放到一定级别不显示泊位、收费员、监控设备图标
        if (t.map.getZoom() < 13) {
          t.clearIconListS();
        }
      });
    },
    // 小组件
    getInitMapBar() {
      let t = this;
      AMapUI.loadUI(["control/BasicControl"], function (BasicControl) {
        t.map.addControl(
          new BasicControl.Zoom({
            position: {
              bottom: "10%",
              right: "25%",
            }, // left top，左上角
          })
        );
      });
    },
    // 加载搜索组件数据
    select(t) {
      // 构造地点查询类
      AMapUI.loadUI(["misc/PoiPicker"], function (PoiPicker) {
        var poiPicker = new PoiPicker(t.autoOptions);
        // 初始化poiPicker
        t.poiPickerReady(poiPicker);
      });
    },
    // 调用搜索组件方法
    poiPickerReady(poiPicker) {
      let that = this;
      window.poiPicker = poiPicker;
      let marker = new AMap.Marker();
      let infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -20),
      });
      poiPicker.on("poiPicked", function (poiResult) {
        let poi = poiResult.item;
        console.log("item", poiResult);
        marker.setMap(that.map);
        infoWindow.setMap(that.map);

        marker.setPosition(poi.location);
        infoWindow.setPosition(poi.location);
        that.map.setCenter(marker.getPosition());
        infoWindow.open(that.map, marker.getPosition());
        let lng = that.map.getCenter().lng;
        let lat = that.map.getCenter().lat;
        let location = [];
        // 高德有部分没有坐标
        if (poi.location) {
          location = [poi.location.lng, poi.location.lat];
        } else {
          location = [lng, lat];
        }
        that.getAmapInit(location);
      });
    },
    // 行政区划
    districtClusterInitPae1(t) {
      AMapUI.load(["ui/geo/DistrictExplorer", "lib/$"], function (DistrictExplorer, $) {
        t.districtExplorer = new DistrictExplorer({
          eventSupport: true, // 打开事件支持
          map: t.map,
        });
        t.map.on("click", function (e) {
          console.log(1112, e);
          if (t.tabContent == false) {
            t.parkDetailWraper = false;
            t.upArea(e.lnglat, t);
            t.tabContent = false;
            t.closeInfo();
          }
        });
        t.upDataRegion(t);
      });
    },
    upArea(lnglat, t) {
      t.districtExplorer.locatePosition(
        lnglat,
        function (error, routeFeatures) {
          t.adcode = routeFeatures[routeFeatures.length - 1].properties.adcode;
          console.log(1113, routeFeatures);
          console.log("lnglat", lnglat);
          console.log("adcode", t.adcode);
          t.upDataRegion(t);
          t.getAreaId();
        },
        {
          levelLimit: 4,
        }
      );
    },
    // 移动选点
    upArea1(lnglat, t) {
      t.districtExplorer.locatePosition(
        lnglat,
        function (error, routeFeatures) {
          t.adcode = routeFeatures[routeFeatures.length - 1].properties.adcode;
          console.log("lnglat", lnglat);
          console.log("adcode", t.adcode);
          t.getAreaId();
        },
        {
          levelLimit: 4,
        }
      );
    },
    upDataRegion(t) {
      t.districtExplorer.loadAreaNode(t.adcode, function (error, areaNode) {
        if (error) {
          console.error(error);
          return;
        }
        t.renderAreaNode(t.districtExplorer, areaNode);
      });
    },
    renderAreaNode(districtExplorer, areaNode) {
      districtExplorer.clearFeaturePolygons();
      districtExplorer.renderParentFeature(areaNode, {
        cursor: "default",
        bubble: true,
        strokeColor: "#00EBEB",
        fillColor: null,
        strokeWeight: 3,
      });
      this.map.setFitView(districtExplorer.getAllFeaturePolygons());
      // console.log("getZoom", this.map.getZoom());
      // this.map.setZoom(5)
    },
    getAreaId() {
      let t = this;
      t.getHostParkNew(this.adcode, 1);
      this.$axios
        .get("/acb/2.0/area/queryAreaByCode", {
          data: {
            areaCode: this.adcode,
          },
        })
        .then((res) => {
          if (res.value) {
            this.selAreaID = res.value.areaId;
            t.$store.commit("SET_regionalCollection", {
              areaId: res.value.areaId,
              dataSource: this.parkCSleParms == 0 ? "" : this.parkCSleParms,
            });
          } else {
            console.log("测试", "没有code先不提示");
          }
        });
    },
    // 热力图
    initHeatMapDom() {
      let t = this;
      this.map.plugin(["AMap.Heatmap"], function () {
        // 初始化heatmap对象
        t.heatmap = new AMap.Heatmap(t.map, {
          visible: true,
          radius: 50, // 给定半径
          opacity: [0, 0.7],
          gradient: {
            // 0.2: "blue",
            // 0.5: "rgb(0, 255, 0)",
            // 1.0: "red",
            0.2: "rgba(255, 73, 73, 0.14)",
            0.65: "rgba(105, 223, 228, 0.36)",
            0.7: "rgba(91, 200, 145, 1)",
            0.9: "rgba(204, 215, 98, 1)",
            1.0: "rgba(212, 119, 119, 1)",
          },
        });
      });
    },
    // 绘制热力图
    getHeatMap() {
      let points = [];
      this.parkNameListAll.forEach((e) => {
        let point = {
          lng: e.longitude / 1000000,
          lat: e.latitude / 1000000,
          count: (e.occupyAmount / e.amount) * 1000,
        };
        points.push(point);
      });
      this.heatmap.setDataSet({
        data: points,
        max: 500,
      });
    },
    // 搜索
    handleCommand(command) {
      if (command !== 0) {
        this.pickerdata = "";
      }
      if (command == 0) {
        this.downName = "位置";
        this.inputPlaceholder = "请输入位置";
        this.parkDetailWraper = false;
        this.addressshow = true;
      } else if (command == 1) {
        this.downName = "停车场";
        this.inputPlaceholder = "请输入停车场";
        this.addressshow = false;
      }
    },
    querySearchAsync(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      if (this.isParkingLot && this.parkNumber == false) {
        this.$axios
          .get("/acb/2.0/systems/loginUser/getParkName", {
            data: {
              page: 1,
              pageSize: 15,
              slaveRelations: "0,1",
              parkName: queryString,
            },
          })
          .then((res) => {
            let arr = res.value.list;
            let newArr = [];
            for (let i = 0; i < arr.length; i++) {
              newArr.push(arr[i]);
            }
            cb(newArr);
          });
      }
    },
    handleSelect(item) {
      if (this.isParkingLot && this.parkNumber !== true) {
        // this.getParkInfo(item.parkId, "#parkId" + item.parkId);
        this.tabContent = true;
        setTimeout(() => {
          this.$store.commit("SET_newregionalCollection", { parkId: item.parkId, onclickTag: 0 });
        }, 500);
      }
    },
    getfocus() {
      this.parkDetailWraper = false;
    },
    //
    setCenterCoordinate(item) {
      console.log("item", item);
      this.map.setCenter([item.longitude / 1000000, item.latitude / 1000000]);
      if (item.onclickTag != 1) {
        this.adcode = item.areaCode;
        this.getHostParkNew(item.areaCode);
      }
      this.map.setZoom(18);
    },
    // 查询停车场的基本信息
    getParkInfo(parkId, target, onclickTag) {
      this.tabContent = true;
      let that = this;
      this.$axios
        .get("/acb/2.0/map/getParkInfo", {
          data: {
            parkId: parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.getDataVideoList(res.value.parkId);
            let item = res.value;
            // this.map.setCenter([
            //   item.longitude / 1000000,
            //   item.latitude / 1000000,
            // ]);
            // this.parkDetailValue = res.value;
            // this.parkDetailWraper = true;
            console.log("this.parkAllItemParms", this.parkAllItemParms);
            this.$store.commit("SET_newregionalCollection", { parkId: parkId, onclickTag: 1 });
            // 初始化的时候限制
            // if (onclickTag != 1) {
            // this.adcode = res.value.areaCode
            // this.getHostParkNew(res.value.areaCode);
            // }
            // this.map.setZoom(18)
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 根据坐标获取地理位置编码
    getAmapInit(lnglat) {
      let that = this;
      AMap.plugin("AMap.Geocoder", function () {
        let geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: "010",
        });
        geocoder.getAddress(lnglat, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // result为对应的地理位置详细信息
            console.log(
              "详情拿到的地址和编码:" +
                result.regeocode.addressComponent.province +
                result.regeocode.addressComponent.adcode
            );
            that.getHostParkNew(result.regeocode.addressComponent.adcode, 1);
          }
        });
      });
    },
    // 地图查询所停车场 点击车场坐标时不清除车场坐标
    // target 1 除了搜索以为的交互。不展示泊位 人员 监控设备
    getHostParkNew(adcode, target) {
      let markers = [];
      this.$axios
        .get("/acb/2.0/map/allParkByAreaCode", {
          data: {
            areaCode: adcode == 100000 ? "all" : adcode,
          },
        })
        .then((res) => {
          this.map.remove(this.map.getAllOverlays("marker"));
          let outInParkData = [];
          for (let a = 0; a < res.value.length; a++) {
            if (
              (res.value[a].dataSource == this.parkCSleParms || this.parkCSleParms == 0) &&
              res.value[a].latitude
            ) {
              outInParkData.push(res.value[a]);
            }
          }
          // this.basePosition = res.value;
          // this.parkNameListAll = res.value;
          this.basePosition = outInParkData;
          this.parkNameListAll = outInParkData;
          if (this.getHeaderSeclet(0) == 0) {
            this.getHeatMap();
            this.heatmap.show();
            console.log("显示热力图", this.parkNameListAll);
          }
          // 0全部 1路内 2 路外。
          for (let i = 0; i < this.basePosition.length; i++) {
            // if (this.basePosition[i].dataSource == this.parkCSleParms || this.parkCSleParms == 0) {
            const marker = new AMap.Marker({
              map: this.map,
              position: [
                this.basePosition[i].longitude / 1000000,
                this.basePosition[i].latitude / 1000000,
              ],
              icon: this.getParkIcon(this.basePosition, i),
              extData: {
                basePosition: this.basePosition[i],
                index: i,
              },
            });
            markers.push(marker);
            this.parksMarkers = markers;
            // 添加点击事件
            marker.on("click", (e) => {
              let parkCllckId = e.target.getExtData().basePosition.parkId;
              this.setIconLarge(e.target);
              this.getParkInfo(parkCllckId, "#parkId" + parkCllckId, 1);
              // 头部选中后点击出现泊位
              this.setIconLists(parkCllckId);
            });
          }
          // onclickTag 0  搜索调用 target 1 除了搜索以为的交互。不展示泊位 人员 监控设备
          if (this.onclickTag == 0 && target != 1) {
            this.screenParkID();
            this.closeInfo();
            this.setIconLists(this.onclickTagParkid);
          }
          if (this.tagMap == 1 && this.ItemMap != 999) {
            // this.map.add(this.mars);
            this.mars = this.map.getAllOverlays("marker");
            this.map.remove(this.map.getAllOverlays("marker"));
          }
        });
    },
    getParkIcon(basePosition, i) {
      // console.log("测试", basePosition);
      let occupy =
        100 -
        (basePosition[i].occupyAmount && basePosition[i].amount
          ? basePosition[i].occupyAmount / basePosition[i].amount
          : 0) *
          100;
      let showIcon;
      if (occupy > 50) {
        showIcon = `${basePosition[i].dataSource === 1 ? this.iconMasterN11 : this.iconMasterY11}`;
      } else if (occupy >= 20) {
        showIcon = `${basePosition[i].dataSource === 1 ? this.iconMasterN33 : this.iconMasterY33}`;
      } else {
        showIcon = `${basePosition[i].dataSource === 1 ? this.iconMasterN22 : this.iconMasterY22}`;
      }
      return showIcon;
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        this.parkSAll = res.value.areaList;

        this.parkSAll.unshift({
          areaCode: "100000",
          areaId: "",
          areaName: "全部",
          areaType: 0,
          depth: 3,
          latitude: 34757975,
          longitude: 113665412,
          parentId: "281479271677952",
        });
        this.getAreaCount(1);
        if (res.value.areaList.length) {
          this.parkAllItemParms = res.value.areaList[1];
          this.adcode = res.value.areaList[1].areaCode || "110000";
          console.log("res.value.areaList[1]", res.value.areaList[1]);
          console.log("parkAllItemParms", this.parkAllItemParms);
          // 左右全局参数
          this.$store.commit("SET_regionalCollection", {
            areaId: this.parkAllItemParms.areaId,
            dataSource: this.parkCSleParms == 0 ? "" : this.parkCSleParms,
          });

          // 初始化选中默认城市
          // this.upDataRegion(this)
          this.getHostParkNew(this.adcode);
          this.initMap();
          if (this.ItemMap == 999) {
            this.getLarge(1);
          }
          // else {
          //    this.getLarge(0);
          // }
        }
      });
    },
    setIconLists(parkId) {
      //  获取泊位
      if (this.getHeaderSeclet(2) == 2) {
        console.log("搜索获取泊位");
        this.getBerthCodeList(parkId);
      }
      //  获取收费员
      if (this.getHeaderSeclet(3) == 3) {
        console.log("搜索获取收费员");
        this.getCollectorList(parkId);
      }
      //  获取监控设备
      if (this.getHeaderSeclet(4) == 4) {
        console.log("搜索监控设备");
        this.getEquipmentList(parkId);
      }
    },
    setBerth(berthList) {
      this.map.remove(this.berthMarkers);
      this.berthMarkers = [];
      for (let i = 0; i < berthList.length; i++) {
        // var icon = new AMap.Icon({
        //   imageSize: new AMap.Size(15, 15),    // 图标尺寸
        //   image: berthList[i].berthUseStatus == 1 ? this.berth1 : this.berth2,  // Icon的图像
        // });
        const markerBerth = new AMap.Marker({
          map: this.map,
          position: [berthList[i].longitude / 1000000, berthList[i].latitude / 1000000],
          icon: berthList[i].berthState == 1 ? this.berth1 : this.berth2,
          extData: {
            berthCode: berthList[i].berthCode,
            index: i,
            berthId: berthList[i].berthId,
          },
        });
        this.berthMarkers.push(markerBerth);
        markerBerth.on("click", (e) => {
          console.log("测试-泊位id", e.target.getExtData().berthId);
          this.closeInfo();
          this.$refs.detailedBox.open(e.target.getExtData().berthId);
        });
      }
    },
    getBerthCodeList(parkId) {
      let t = this;
      this.$axios
        .get("/acb/2.0/bigScreen/map/berthInfoList", {
          data: {
            parkId: parkId,
          },
        })
        .then((res) => {
          console.log("泊位", res.value);
          let data = res.value ? res.value : [];
          this.setBerth(data);
        });
    },
    setCollector(berthList) {
      this.map.remove(this.collectorMarkers);
      this.collectorMarkers = [];
      for (let i = 0; i < berthList.length; i++) {
        //  var icon = new AMap.Icon({
        //   imageSize: new AMap.Size(27, 27),    // 图标尺寸
        //   image: berthList[i].pdaManagerRole == 1 ? this.collector2 : this.collector1,  // Icon的图像
        // });
        const markercollector = new AMap.Marker({
          map: this.map,
          position: [berthList[i].longitude / 1000000, berthList[i].latitude / 1000000],
          icon: berthList[i].pdaManagerRole == 1 ? this.collector2 : this.collector1,
          extData: {
            pdaManagerId: berthList[i].pdaManagerId,
            index: i,
          },
        });
        this.collectorMarkers.push(markercollector);
        markercollector.on("click", (e) => {
          console.log("测试", e.target.getExtData());
          this.closeInfo();
          this.$refs.personnel.open(e.target.getExtData().pdaManagerId);
        });
      }
    },
    getCollectorList(parkId) {
      this.$axios
        .get("/acb/2.0/bigScreen/map/pdaManagerList", {
          data: {
            parkId: parkId,
          },
        })
        .then((res) => {
          console.log("收费员", res.value);
          let data = res.value ? res.value : [];
          this.setCollector(data);
        });
    },
    setEquipment(berthList) {
      this.map.remove(this.equipmentMarkers);
      this.equipmentMarkers = [];
      for (let i = 0; i < berthList.length; i++) {
        // var icon = new AMap.Icon({
        //   imageSize: new AMap.Size(27, 27),    // 图标尺寸
        //   image: this.highlevel,  // Icon的图像
        // });
        const markerequipment = new AMap.Marker({
          map: this.map,
          position: [berthList[i].longitude / 1000000, berthList[i].latitude / 1000000],
          icon: this.highlevel,
          extData: {
            monitorEquipmentId: berthList[i].monitorEquipmentId,
            index: i,
            monitorEquipmentName: berthList[i].monitorEquipmentName,
            type: berthList[i].type,
          },
        });
        this.equipmentMarkers.push(markerequipment);
        markerequipment.on("click", (e) => {
          // type: 2  啥都 不弹   type 1 弹
          console.log("测试", e.target.getExtData().monitorEquipmentId);
          if (e.target.getExtData().type == 1) {
            this.showVideo({
              monitorEquipmentId: e.target.getExtData().monitorEquipmentId,
              monitorEquipmentName: e.target.getExtData().monitorEquipmentName,
            });
          }
        });
      }
    },
    getEquipmentList(parkId) {
      let t = this;
      this.$axios
        .get("/acb/2.0/bigScreen/map/equipmentList", {
          data: {
            parkId: parkId,
          },
        })
        .then((res) => {
          console.log("监控设备", res.value);
          let equipMentData = res.value ? res.value : [];
          this.setEquipment(equipMentData);
        });
    },
    closeInfo() {
      this.$refs.personnel.close();
      this.$refs.detailedBox.close();
    },
    clearIconListS() {
      this.map.remove(this.berthMarkers);
      this.map.remove(this.collectorMarkers);
      this.map.remove(this.equipmentMarkers);
    },
    // 图标放大
    setIconLarge(parksMarkers, parkId) {
      if (this.targetIcon) {
        this.targetIcon.setIcon(this.targetIconIMG);
      }
      // console.log("e=>setIconLarge", parksMarkers);
      this.targetIconIMG = parksMarkers.getIcon();
      parksMarkers.setIcon(
        new AMap.Icon({
          image: parksMarkers.getIcon(), // Icon的图像
          imageSize: new AMap.Size(35, 35),
        })
      );
      this.targetIcon = parksMarkers;
    },
    // 筛选当前需要放大的图标
    screenParkID() {
      for (let inTag of this.parksMarkers) {
        if (inTag.getExtData().basePosition.parkId == this.onclickTagParkid) {
          this.setIconLarge(inTag);
        }
      }
    },
  },
  created() {
    // this.getLargeInfarce();
    // this.getArea();
  },
  mounted() {
    this.$nextTick(() => {
      this.getArea();
      if (this.path) {
        this.scollHeight = document.documentElement.clientHeight;
        this.leftContent = document.querySelector(
          this.tabContent ? ".bgLeftDP" : ".leftChartsContentDP"
        );
        this.rightContent = document.querySelector(
          this.tabContent ? ".bgRightDP" : ".rightChartsContentDP"
        );
        window.onresize = () => {
          this.scollHeight = document.documentElement.clientHeight;
        };
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.timmer);
    this.timmer = null;
  },
};
</script>

<style scoped lang="stylus">
.homeContent {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
  .titleBg{
    background: url("../../assets/img/home_top_logo.png") no-repeat;
    background-position: 0 center;
    padding-left: 120px;
    margin: 0 auto;
    width: 192px;
    text-align :center;
  }
    .title{
       height:40px;
      line-height: 40px;
      font-size: 24px;
      background: linear-gradient(180deg, #afecff, #4edbff);
      -webkit-background-clip: text;
      color: transparent;
      margin: 0 auto;
      display: inline-block;
    }

#container {
  width: 100%;
  height: 100%;
}

.header {
  position: absolute;
  top: 0px;
  z-index: 999;
  text-align: center;
}

.header img {
  margin-top: -42px;
}

.titleIcon {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  padding-top: 6px;
  display: inline-block;
  padding-bottom: 6px;
}
@media screen and (max-width 1680px) {
  .titleIcon {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    padding-top: 6px;
    display: inline-block;
    padding-bottom: 6px;
   }
}
.choiceovC {
  width: 432px;
  margin: 0 auto;
  display: flex;
  transform: translateY(-20px);
}

.choiceovCItem {
  // flex: 1;
  height: 20px;
  margin-left: 33px
}

.ccItem {
  position: relative;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #89CBE8;
  cursor: pointer;
}

.ccItem:after {
  content: ' ';
  position: absolute;
  left: -14px;
  top: 33%;
  width: 8px;
  height: 8px;
  background-color: #89CBE8;
  border-radius: 4px;
}

.ccItemClor {
  color: #49B2FF;
}

.ccItem2:after {
  content: ' ';
  position: absolute;
  left: -14px;
  top: 33%;
  width: 8px;
  height: 8px;
  background-color: #49B2FF;
  border-radius: 4px;
}

.parkDetailWraper {
  color: #D8D8D8;
  width: 296px;
  padding: 10px 16px;
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  left: calc(25% + 5px);
  top: 125px;
  font-size: 16px;
  background: linear-gradient(180deg, rgba(182, 246, 255, 0.16) 0%, rgba(132, 173, 255, 0.19) 100%);
  box-shadow: inset 0px 0px 4px 0px rgba(143, 248, 255, 0.76);
  border-radius: 4px;
  backdrop-filter: blur(5px);
}

#container /deep/ {
  .activeIcon {
    width: 25px;
    height: 32px;
  }
  .iconDefault {
    width: 20px;
    height: 26px;
  }
}

// 全部车场 路内车场 路外车场
.parkCS {
  position: absolute;
  bottom: 62px;
  left: calc(48% - 122px);
  z-index: 99;
  display: flex;
}
  @media screen and (max-width 3000px) {
    .parkCS {
        position: absolute;
        bottom: 72px;
        left: calc(48% - 122px);
        z-index: 99;
        display: flex;
    }
  }
.parkCSItem {
  width: 102px;
  height: 32px;
  background: url('./images/parkIcon.png') no-repeat center;
  line-height: 32px;
  text-align: center;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #69B7CC;
  margin-left: 6px;
  cursor: pointer;
}
.parkCSItemHover {
  width: 102px;
  height: 32px;
  background: url('./images/parkIconHover.png') no-repeat center;
  line-height: 32px;
  text-align: center;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #69B7CC;
  margin-left: 6px;
  cursor: pointer;
}

.parkCSItemColor {
  color: #65FFFF;
}

// 全部车场
.parkAll {
  width: 50%;
  position: absolute;
  bottom: 20px;
  left: 25%;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(182, 214, 255, 0.1) 0%, rgba(132, 173, 255, 0.19) 100%);
  border-radius: 2px;
  backdrop-filter: blur(5px);
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}
  @media screen and (min-width 3000px) {
    .parkAll {
      width: 50%;
      position: absolute;
      bottom: 30px;
      left: 25%;
      z-index: 99;
      display: flex;
      justify-content: space-between;
      background: linear-gradient(180deg, rgba(182, 214, 255, 0.1) 0%, rgba(132, 173, 255, 0.19) 100%);
      border-radius: 2px;
      backdrop-filter: blur(5px);
      padding-left: 8px;
      padding-right: 8px;
      box-sizing: border-box;
    }
  }
.parkBox {
  // max-width: 852px;
  overflow-x: hidden;
  // overflow-x: auto;
  white-space: nowrap;
  // line-height: 32px;
}
.parkBox2 {
  // background-color: blue;
  width: 100%;
  display: flex;
  justify-content: center;
}
.parkBoxMain{
  // background-color: blue;
}
.parkAllItem {
  padding: 10px 8px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  // margin-left: 6px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #78B5FF;
  cursor: pointer;
  display: inline-block;
  // height: 32px;
}

.parkAllItemColor {
  color: #00EBEB;
}

.parkAllImg {
  width: 24px;
  height: 24px;
  padding-top: 5px;
}

.homeContent >>>.large{
  position: relative
  width: 100px;
  .imgpark{
    width: 20px;
    height: 24px;
  }
  .line {
    width: 10px;
    height: 8px;
    position: absolute;
    left: -11px;
    bottom: 0;
  }
  .largeimgpark{
    position: absolute;
    top: -18px;
    left: 30px;
    padding: 5px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 158, 94, 1);
    background: rgba(255, 115, 0, 0.4)
    // background: linear-gradient(180deg, rgba(182,214,255,0.1) 0%, rgba(132,173,255,0.19) 100%);
    border-radius: 4px
  }
}
// 剩余车场
.fullFigure {
  width: 100px;
  height: 118px;
  background: linear-gradient(180deg, rgba(182, 246, 255, 0.16) 0%, rgba(132, 173, 255, 0.19) 100%);
  box-shadow: inset 0px 0px 1px 0px rgba(143, 248, 255, 0.44);
  border-radius: 4px;
  backdrop-filter: blur(5px)
  position: absolute;
  top: 8%;
  right: calc(26% - 10px);
  display: block;
  padding-left 12px;
  padding-top 12px;
  z-index: 999;
  div {
    transform scale(0.96)
    margin: 10px 0;
    font-size 12px;
    height: 20px;
    line-height: 20px;
    span {
      font-size: 12px;
      color: #fff;
    }
  }
  h1{
    color: #fff;
    font-size 14px;
  }
  .fullFigure-one {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #1FFFD3;
    border-radius: 2px;
    margin-right 5px;
    margin-bottom: -3px;
  }
  .fullFigure-two {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #FFA62A;
    border-radius: 2px;
    margin-right 5px;
    margin-bottom: -3px;
  }
  .fullFigure-three {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #FE5151;
    border-radius: 2px;
    margin-right 5px;
    margin-bottom: -3px;
  }
}
@media screen and (max-width 1680px) {
  .fullFigure {
      width: 86px;
      height: 98px;
      background: linear-gradient(180deg, rgba(182, 246, 255, 0.16) 0%, rgba(132, 173, 255, 0.19) 100%);
      box-shadow: inset 0px 0px 1px 0px rgba(143, 248, 255, 0.44);
      border-radius: 4px;
      backdrop-filter: blur(5px)
      position: absolute;
      top: 8%;
      right: calc(26% - 10px);
      display: block;
      padding-left 6px;
      padding-top 12px;
      z-index: 999;
      box-sizing: border-box;
  div {
      transform scale(0.96)
      margin: 2px 0;
      font-size 12px;
      height: 20px;
      // line-height: 20px;
      span {
        font-size: 10px;
        color: #fff;
      }
  }
  h1{
      color: #fff;
      font-size 12px;
  }
    .fullFigure-one {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: #1FFFD3;
        border-radius: 2px;
        margin-right 5px;
        margin-bottom: -3px;
    }
    .fullFigure-two {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: #FFA62A;
        border-radius: 2px;
        margin-right 5px;
        margin-bottom: -3px;
    }
    .fullFigure-three {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: #FE5151;
        border-radius: 2px;
        margin-right 5px;
        margin-bottom: -3px;
    }
  }

}
</style>
// 搜索
<style lang="stylus">
#pickerInput::-webkit-input-placeholder {
  color: #D8D8D8;
}

.inMapMain .parkSearch {
  position: absolute;
  z-index: 2;
  left: calc(25% + 5px);
  top: 82px;
  width: 296px;
  height: 32px;
  border-radius: 4px;
  padding-left: 40px;
  box-shadow: inset 0px 0px 4px 0px rgba(143, 248, 255, 0.76);
  background: url('./images/selectov.png') no-repeat 268px center;
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  input {
    display: inline-block;
    background: none;
    border: none;
    padding: 0;
    height: 38px;
    color: #D8D8D8;
    opacity: 1;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    // padding-left: 40px;
    padding-bottom: 18px;
    &:hover, &:focus {
      background: transparent;
    }
  }

  .parkSearch-menuBar {
    width: 110px;
    font-family: MicrosoftYaHei;
    color: #F0F0F0;
  }

  .menuBarFs {
    font-size: 14px;
  }
}
.parkSearch .el-autocomplete{
  position: relative;
  display: inline-block;
  transform: translateX(34px);
}
.my-autocomplete {
  width: 252px !important;
  margin-left: -40px;
  border: 0px;
  background: linear-gradient(180deg, rgba(182, 246, 255, 0.16) 0%, rgba(132, 173, 255, 0.19) 100%) !important;
  box-shadow: inset 0px 0px 4px 0px rgba(143, 248, 255, 0.76);
  border-radius: 4px;
  backdrop-filter: blur(5px);

  .el-autocomplete-suggestion__wrap {
    max-height: 269px;
    border: none;
    width: 271px;
    overflow-y: scroll !important;

    li:hover {
      background: rgba(67, 101, 242, 0.3) !important;
    }

    li {
      background: url('./images/li_bg.png') no-repeat 22px center;
      padding-left: 50px;
      line-height: 30px;

      .name {
        color: #fff;
      }
    }
  }

  .popper__arrow {
    border-bottom-color: rgba(67, 101, 242, 0.3) !important;
  }

  .popper__arrow::after {
    border-bottom-color: #000d3f !important;
  }
}

.dropdownWrapper {
  width: 40px;
  height: 38px;
  left: 8px;
  top: 29%;
  position: absolute;
}

.dropdownStyle {
  box-shadow: inset 0px 0px 4px 0px rgba(143, 248, 255, 0.76);
  backdrop-filter: blur(5px);
  background: linear-gradient(180deg, rgba(182, 246, 255, 0.16) 0%, rgba(132, 173, 255, 0.19) 100%);
  border: none;
}

.dropdownStyle li.el-dropdown-menu__item {
  color: #fff;
}

.dropdownStyle .el-dropdown-menu__item:focus, .dropdownStyle .el-dropdown-menu__item:not(.is-disabled):hover {
  background: rgba(67, 101, 242, 0.3);
  color: #ffffff;
}

.dropdownStyle .popper__arrow {
  border-bottom-color: #002F63 !important;
}

.dropdownStyle .popper__arrow::after {
  border-bottom-color: #002F63 !important;
}

.el-popper[x-placement^=bottom] {
  margin-top: -6px;
  margin-left: -7px;
}
.bottomS{
  width: 50%;
  position: absolute;
  bottom: 20px;
  left: 25%;
  height: 100px;
  background: linear-gradient(180deg, rgba(0,5,32,0) 0%, rgba(0,5,32,0.8) 100%);
  // background-color: red;
  z-index: 9;
}
</style>
